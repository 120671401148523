export default {
    "scalars": [
        4,
        6,
        7,
        8,
        10,
        17,
        20,
        25,
        26,
        29,
        44,
        46,
        51,
        52,
        53,
        58,
        60,
        63,
        70,
        73,
        84,
        88,
        90,
        94,
        97,
        101,
        102,
        105,
        113,
        118,
        125,
        131,
        136,
        142,
        151,
        156,
        157,
        163,
        164,
        165,
        166,
        171,
        175,
        178,
        182,
        186,
        189,
        207,
        208,
        211,
        220,
        225,
        226,
        233,
        234,
        238,
        241,
        242,
        245,
        262,
        263,
        265,
        267,
        278,
        281,
        284,
        288,
        291,
        304,
        305,
        306,
        314,
        324,
        332,
        345,
        350,
        351,
        352,
        353,
        356,
        361,
        369,
        372,
        378,
        379,
        383,
        386,
        392,
        408,
        409,
        415,
        416,
        420,
        430,
        439,
        453,
        460,
        461,
        469,
        480,
        494,
        496,
        498,
        503,
        504,
        506,
        512,
        513,
        519,
        525,
        527,
        528,
        532,
        533,
        534,
        542,
        553,
        555,
        569,
        570,
        586,
        591,
        593,
        597,
        598,
        613,
        622,
        626,
        633,
        634,
        637,
        640,
        641,
        654,
        659,
        667,
        670,
        674,
        675,
        689,
        694,
        695,
        707,
        711,
        712,
        713,
        717,
        718,
        720,
        726,
        733,
        739,
        743,
        746,
        748,
        750,
        751,
        764
    ],
    "types": {
        "Adapter": {
            "description": [
                613
            ],
            "endpoint": [
                613
            ],
            "id": [
                324
            ],
            "settings": [
                2
            ],
            "type": [
                4
            ],
            "__typename": [
                613
            ]
        },
        "AdapterDataInput": {
            "description": [
                613
            ],
            "endpoint": [
                613
            ],
            "settings": [
                3
            ],
            "type": [
                4
            ],
            "__typename": [
                613
            ]
        },
        "AdapterSetting": {
            "IsSecure": [
                142
            ],
            "id": [
                324
            ],
            "key": [
                613
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AdapterSettingInput": {
            "IsSecure": [
                142
            ],
            "key": [
                613
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AdapterType": {},
        "Address": {
            "addressType": [
                6
            ],
            "city": [
                613
            ],
            "coordinate": [
                209
            ],
            "country": [
                613
            ],
            "entrance": [
                613
            ],
            "googlePlaceID": [
                613
            ],
            "id": [
                324
            ],
            "isDefault": [
                142
            ],
            "state": [
                613
            ],
            "streetName": [
                613
            ],
            "streetNumber": [
                613
            ],
            "zipcode": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AddressType": {},
        "AdmissionFeeType": {},
        "AllowUserTaskStatus": {},
        "AmenitiesList": {
            "items": [
                12
            ],
            "pager": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "AmenitiesOrderBy": {},
        "AmenitiesWhereInput": {
            "accessibleToBuildingIds": [
                324
            ],
            "id": [
                324
            ],
            "isActive": [
                142
            ],
            "isReservable": [
                142
            ],
            "location": [
                613
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Amenity": {
            "accessibleToBuildingIds": [
                613
            ],
            "accessibleToBuildings": [
                14
            ],
            "amenityCalendarSettings": [
                16
            ],
            "availableDates": [
                712,
                {
                    "where": [
                        13,
                        "AmenityAvailableDatesWhereInput!"
                    ]
                }
            ],
            "bookingOptions": [
                140,
                {
                    "where": [
                        141,
                        "BookingOptionsWhereInput!"
                    ]
                }
            ],
            "cancellationPolicy": [
                613
            ],
            "customAvailableDates": [
                712,
                {
                    "where": [
                        13,
                        "AmenityAvailableDatesWhereInput!"
                    ]
                }
            ],
            "customBookingOptions": [
                140,
                {
                    "where": [
                        141,
                        "BookingOptionsWhereInput!"
                    ]
                }
            ],
            "customPaymentOptions": [
                440,
                {
                    "data": [
                        218,
                        "CustomPaymentOptionsInput!"
                    ]
                }
            ],
            "depositAmount": [
                305
            ],
            "description": [
                613
            ],
            "enabledPaymentMethods": [
                439
            ],
            "firstAvailableDate": [
                712
            ],
            "id": [
                324
            ],
            "isActive": [
                142
            ],
            "isReservable": [
                142
            ],
            "lastAvailableDate": [
                712
            ],
            "location": [
                613
            ],
            "locationBuilding": [
                172
            ],
            "locationBuildingId": [
                324
            ],
            "managersToNotify": [
                324
            ],
            "media": [
                27
            ],
            "name": [
                613
            ],
            "pmsChargeCode": [
                613
            ],
            "pmsDepositChargeCode": [
                613
            ],
            "pmsDepositGlAccountNumber": [
                613
            ],
            "pmsGlAccountNumber": [
                613
            ],
            "requireAgreement": [
                142
            ],
            "requireApproval": [
                142
            ],
            "stripeAccountId": [
                613
            ],
            "stripeProductId": [
                613
            ],
            "timezone": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityAvailableDatesWhereInput": {
            "durationInMinutes": [
                332
            ],
            "endDate": [
                712
            ],
            "startDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "AmenityBuilding": {
            "Building": [
                172
            ],
            "BuildingId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "AmenityCalendarCreateInput": {
            "amenityId": [
                324
            ],
            "checkIn": [
                613
            ],
            "checkOut": [
                613
            ],
            "openingHours": [
                31
            ],
            "reservationSettings": [
                49
            ],
            "type": [
                17
            ],
            "__typename": [
                613
            ]
        },
        "AmenityCalendarSettings": {
            "amenity": [
                12
            ],
            "amenityId": [
                324
            ],
            "checkIn": [
                613
            ],
            "checkOut": [
                613
            ],
            "id": [
                324
            ],
            "isActive": [
                142
            ],
            "openingHours": [
                30
            ],
            "reservationSettings": [
                48
            ],
            "type": [
                17
            ],
            "__typename": [
                613
            ]
        },
        "AmenityCalendarType": {},
        "AmenityCalendarUnavailabilitiesWhereInput": {
            "accessibleToBuildingIds": [
                324
            ],
            "amenityIds": [
                324
            ],
            "endDate": [
                712
            ],
            "startDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "AmenityCalendarUnavailability": {
            "amenityCalendar": [
                16
            ],
            "amenityCalendarId": [
                324
            ],
            "createdAt": [
                712
            ],
            "endDate": [
                712
            ],
            "id": [
                324
            ],
            "reason": [
                613
            ],
            "startDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "AmenityCalendarUnavailabilityOrderBy": {},
        "AmenityCalendarUpdateInput": {
            "reservationSettings": [
                50
            ],
            "__typename": [
                613
            ]
        },
        "AmenityCancelManyWhereInput": {
            "idIn": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "AmenityCancellationDenialInput": {
            "note": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityCreateInput": {
            "accessibleToBuildingIds": [
                324
            ],
            "cancellationPolicy": [
                613
            ],
            "depositAmount": [
                305
            ],
            "description": [
                613
            ],
            "enabledPaymentMethods": [
                439
            ],
            "isActive": [
                142
            ],
            "isReservable": [
                142
            ],
            "location": [
                613
            ],
            "locationBuildingId": [
                324
            ],
            "managersToNotify": [
                324
            ],
            "media": [
                28
            ],
            "name": [
                613
            ],
            "pmsChargeCode": [
                613
            ],
            "pmsDepositChargeCode": [
                613
            ],
            "pmsDepositGlAccountNumber": [
                613
            ],
            "pmsGlAccountNumber": [
                613
            ],
            "requireAgreement": [
                142
            ],
            "requireApproval": [
                142
            ],
            "stripeAccountId": [
                613
            ],
            "stripeProductId": [
                613
            ],
            "timezone": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityDepositStatus": {},
        "AmenityDepositType": {},
        "AmenityMedia": {
            "id": [
                324
            ],
            "type": [
                29
            ],
            "url": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityMediaCreateInput": {
            "type": [
                29
            ],
            "url": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityMediaType": {},
        "AmenityOpeningHours": {
            "closingTime": [
                613
            ],
            "dayOfWeek": [
                220
            ],
            "openingTime": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityOpeningHoursCreateInput": {
            "closingTime": [
                613
            ],
            "dayOfWeek": [
                220
            ],
            "openingTime": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservation": {
            "FeeAmount": [
                305
            ],
            "agreementSignedAt": [
                712
            ],
            "amenity": [
                12
            ],
            "amenityId": [
                324
            ],
            "amountPaid": [
                305
            ],
            "cancellationNote": [
                613
            ],
            "cancellationRequestNote": [
                613
            ],
            "cancellationRequestStatus": [
                186
            ],
            "createdAt": [
                712
            ],
            "denialReason": [
                613
            ],
            "depositAutoReleaseDate": [
                712
            ],
            "depositChargeDate": [
                712
            ],
            "depositChargeNote": [
                613
            ],
            "depositChargedAmount": [
                305
            ],
            "depositPaymentLink": [
                613
            ],
            "depositStatus": [
                25
            ],
            "depositType": [
                26
            ],
            "endDate": [
                712
            ],
            "externalPaymentId": [
                613
            ],
            "id": [
                324
            ],
            "paidAt": [
                712
            ],
            "paymentLink": [
                613
            ],
            "paymentMethod": [
                439
            ],
            "paymentStatus": [
                46
            ],
            "pmsPaymentId": [
                613
            ],
            "pmsRefundId": [
                613
            ],
            "pmsReversalId": [
                613
            ],
            "refundAmount": [
                305
            ],
            "refundMethod": [
                498
            ],
            "refundedAt": [
                712
            ],
            "reservationId": [
                613
            ],
            "reservationType": [
                53
            ],
            "startDate": [
                712
            ],
            "status": [
                51
            ],
            "statusUpdateNote": [
                613
            ],
            "timeLine": [
                52
            ],
            "unit": [
                715
            ],
            "unitId": [
                324
            ],
            "user": [
                721
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationCancelAndRefundInput": {
            "cancellationNote": [
                613
            ],
            "refundAmount": [
                305
            ],
            "refundDetails": [
                497
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationCancelInput": {
            "cancellationNote": [
                613
            ],
            "refundId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationCreateInput": {
            "agreementSigned": [
                142
            ],
            "amenityId": [
                324
            ],
            "endDate": [
                712
            ],
            "paymentDetails": [
                438
            ],
            "paymentMethod": [
                439
            ],
            "requireApproval": [
                142
            ],
            "reservationOptionId": [
                324
            ],
            "startDate": [
                712
            ],
            "unitId": [
                324
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationCustomCreateInput": {
            "agreementSigned": [
                142
            ],
            "amenityId": [
                324
            ],
            "endDate": [
                712
            ],
            "paymentDetails": [
                438
            ],
            "paymentMethod": [
                439
            ],
            "priceOverride": [
                305
            ],
            "startDate": [
                712
            ],
            "unitId": [
                324
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationDenialInput": {
            "reason": [
                613
            ],
            "refundId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationDepositChargeInput": {
            "AmenityReservationId": [
                324
            ],
            "ChargeAmount": [
                305
            ],
            "ChargeNote": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationDepositReleaseInput": {
            "AmenityReservationId": [
                324
            ],
            "ReleaseNote": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationDepositTakeInput": {
            "AmenityReservationId": [
                324
            ],
            "depositCashInput": [
                231
            ],
            "depositCheckInput": [
                232
            ],
            "depositType": [
                26
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationOption": {
            "amenityCalendarId": [
                324
            ],
            "durationInMinutes": [
                332
            ],
            "id": [
                324
            ],
            "label": [
                613
            ],
            "paymentOptions": [
                440
            ],
            "price": [
                305
            ],
            "priceByPaymentMethod": [
                488
            ],
            "productName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationOptionCreateInput": {
            "durationInMinutes": [
                332
            ],
            "label": [
                613
            ],
            "price": [
                305
            ],
            "productName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationOptionUpdateInput": {
            "amenityCalendarId": [
                324
            ],
            "durationInMinutes": [
                332
            ],
            "label": [
                613
            ],
            "price": [
                305
            ],
            "productName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationOrderBy": {},
        "AmenityReservationPayInput": {
            "reservationId": [
                613
            ],
            "stripeCheckoutSessionId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationPaymentStatus": {},
        "AmenityReservationRequestCancelInput": {
            "cancellationRequestNote": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationSettings": {
            "amenityCalendarId": [
                324
            ],
            "maxReservationDaysInAdvance": [
                332
            ],
            "minReservationDaysInAdvance": [
                332
            ],
            "postReservationBufferMinutes": [
                332
            ],
            "reservationDurationOptions": [
                332
            ],
            "reservationOptions": [
                41
            ],
            "reservationQuotaFrequency": [
                512
            ],
            "reservationQuotaLimit": [
                332
            ],
            "reservationQuotaType": [
                513
            ],
            "slotCapacity": [
                332
            ],
            "slotDurationInMinutes": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationSettingsCreateInput": {
            "maxReservationDaysInAdvance": [
                332
            ],
            "minReservationDaysInAdvance": [
                332
            ],
            "postReservationBufferMinutes": [
                332
            ],
            "reservationDurationOptions": [
                332
            ],
            "reservationOptions": [
                42
            ],
            "reservationQuotaFrequency": [
                512
            ],
            "reservationQuotaLimit": [
                332
            ],
            "reservationQuotaType": [
                513
            ],
            "slotCapacity": [
                332
            ],
            "slotDurationInMinutes": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationSettingsUpdateInput": {
            "reservationOptions": [
                43
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationStatus": {},
        "AmenityReservationTimeLine": {},
        "AmenityReservationType": {},
        "AmenityReservationUserCreateInput": {
            "agreementSigned": [
                142
            ],
            "amenityId": [
                324
            ],
            "endDate": [
                712
            ],
            "notes": [
                613
            ],
            "paymentMethod": [
                439
            ],
            "reservationOptionId": [
                324
            ],
            "startDate": [
                712
            ],
            "unitId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationWhereInput": {
            "startDateGt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "AmenityReservationsWhereInput": {
            "accessibleToBuildingIds": [
                324
            ],
            "amenityIds": [
                324
            ],
            "cancellationRequestStatuses": [
                186
            ],
            "endDate": [
                712
            ],
            "reservationType": [
                53
            ],
            "startDate": [
                712
            ],
            "status": [
                51
            ],
            "statuses": [
                51
            ],
            "timeLines": [
                52
            ],
            "unitId": [
                324
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "AmenityUpdateInput": {
            "accessibleToBuildingIds": [
                324
            ],
            "cancellationPolicy": [
                613
            ],
            "depositAmount": [
                305
            ],
            "description": [
                613
            ],
            "enabledPaymentMethods": [
                439
            ],
            "isActive": [
                142
            ],
            "isReservable": [
                142
            ],
            "location": [
                613
            ],
            "locationBuildingId": [
                324
            ],
            "managersToNotify": [
                324
            ],
            "media": [
                28
            ],
            "name": [
                613
            ],
            "pmsChargeCode": [
                613
            ],
            "pmsDepositChargeCode": [
                613
            ],
            "pmsDepositGlAccountNumber": [
                613
            ],
            "pmsGlAccountNumber": [
                613
            ],
            "price": [
                305
            ],
            "requireAgreement": [
                142
            ],
            "requireApproval": [
                142
            ],
            "stripeAccountId": [
                613
            ],
            "stripeProductId": [
                613
            ],
            "timezone": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Any": {},
        "AppDashboardLink": {
            "appPageLinkDate": [
                71
            ],
            "audienceType": [
                60
            ],
            "building": [
                172
            ],
            "buildingId": [
                324
            ],
            "community": [
                192
            ],
            "communityId": [
                324
            ],
            "disabled": [
                142
            ],
            "displayText": [
                613
            ],
            "emailLinkData": [
                252
            ],
            "externalAppLinkData": [
                289
            ],
            "iconResourceKey": [
                613
            ],
            "id": [
                324
            ],
            "linkType": [
                63
            ],
            "ordering": [
                332
            ],
            "organization": [
                426
            ],
            "organizationId": [
                324
            ],
            "phoneLinkData": [
                463
            ],
            "section": [
                534
            ],
            "smsLinkData": [
                582
            ],
            "webLinkData": [
                756
            ],
            "__typename": [
                613
            ]
        },
        "AppDashboardLinkAudienceType": {},
        "AppDashboardLinkCreateInput": {
            "appPageLinkData": [
                72
            ],
            "buildingId": [
                324
            ],
            "communityId": [
                324
            ],
            "disabled": [
                142
            ],
            "displayText": [
                613
            ],
            "emailLinkData": [
                253
            ],
            "externalAppLinkData": [
                290
            ],
            "iconResourceKey": [
                613
            ],
            "linkType": [
                63
            ],
            "organizationId": [
                324
            ],
            "phoneLinkData": [
                464
            ],
            "section": [
                534
            ],
            "smsLinkData": [
                583
            ],
            "webLinkData": [
                757
            ],
            "__typename": [
                613
            ]
        },
        "AppDashboardLinkOrderInput": {
            "id": [
                613
            ],
            "ordering": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "AppDashboardLinkType": {},
        "AppDashboardLinkUpdateInput": {
            "appPageLinkData": [
                72
            ],
            "buildingId": [
                324
            ],
            "communityId": [
                324
            ],
            "disabled": [
                142
            ],
            "displayText": [
                613
            ],
            "emailLinkData": [
                253
            ],
            "externalAppLinkData": [
                290
            ],
            "iconResourceKey": [
                613
            ],
            "ordering": [
                332
            ],
            "phoneLinkData": [
                464
            ],
            "section": [
                534
            ],
            "smsLinkData": [
                583
            ],
            "webLinkData": [
                757
            ],
            "__typename": [
                613
            ]
        },
        "AppLinkType": {
            "android": [
                613
            ],
            "ios": [
                613
            ],
            "uri_prefix": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AppLinkTypeInput": {
            "android": [
                613
            ],
            "ios": [
                613
            ],
            "uri_prefix": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AppMoveInSettings": {
            "isVisibleToUser": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "AppOnboardingScreen": {
            "bullets": [
                613
            ],
            "screenType": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AppOnboardingScreenInput": {
            "bullets": [
                613
            ],
            "screenType": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AppOnboardingStatus": {},
        "AppPageLinkData": {
            "pageName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AppPageLinkDataInput": {
            "pageName": [
                73
            ],
            "__typename": [
                613
            ]
        },
        "AppPageName": {},
        "AppReputationSettings": {
            "reputationLink": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AppSettings": {
            "amenitiesEnabled": [
                142
            ],
            "awayNoticeSettings": [
                134,
                {
                    "where": [
                        758
                    ]
                }
            ],
            "communityId": [
                613
            ],
            "dashboardLinks": [
                59
            ],
            "embeddedApplications": [
                517
            ],
            "legacy": [
                371
            ],
            "moveInSettings": [
                67
            ],
            "renewalSurveySettings": [
                505
            ],
            "rentPaymentEnabled": [
                142
            ],
            "reputation": [
                74
            ],
            "serviceRequestsSettings": [
                560
            ],
            "termsAndConditionsStatus": [
                707,
                {
                    "appIdSuffix": [
                        613,
                        "String!"
                    ]
                }
            ],
            "thermostatsEnabled": [
                142
            ],
            "visitorsEnabled": [
                142,
                {
                    "where": [
                        758
                    ]
                }
            ],
            "visitorsSettings": [
                754,
                {
                    "where": [
                        758
                    ]
                }
            ],
            "__typename": [
                613
            ]
        },
        "Applicant": {
            "applicantSubmissionStep": [
                88
            ],
            "applicationStatus": [
                97
            ],
            "assetsReport": [
                99
            ],
            "birthDate": [
                712
            ],
            "buildingId": [
                324
            ],
            "city": [
                613
            ],
            "coApplicants": [
                76
            ],
            "createdAt": [
                712
            ],
            "creditReport": [
                212
            ],
            "criminalBackgroundCheck": [
                214
            ],
            "decidingPMUser": [
                721
            ],
            "decidingPMUserId": [
                324
            ],
            "documents": [
                79
            ],
            "email": [
                613
            ],
            "firstName": [
                613
            ],
            "id": [
                324
            ],
            "identityVerification": [
                325
            ],
            "incomeReport": [
                329
            ],
            "lastName": [
                613
            ],
            "leaseRecommendationStatus": [
                369
            ],
            "leaseTerms": [
                83
            ],
            "mainApplicantId": [
                324
            ],
            "middleName": [
                613
            ],
            "organizationId": [
                324
            ],
            "pets": [
                459
            ],
            "phone": [
                613
            ],
            "relation": [
                613
            ],
            "screeningStatus": [
                533
            ],
            "state": [
                613
            ],
            "street": [
                613
            ],
            "summary": [
                89
            ],
            "type": [
                90
            ],
            "unit": [
                715
            ],
            "unitId": [
                324
            ],
            "zip": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantCreateResponse": {
            "id": [
                324
            ],
            "idvToken": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantDocument": {
            "availableUntil": [
                712
            ],
            "lastModified": [
                712
            ],
            "url": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantDocuments": {
            "applicantConsentDocument": [
                78
            ],
            "assetsReportDocument": [
                78
            ],
            "creditScoreReportDocument": [
                78
            ],
            "identityVerificationImagesDocument": [
                78
            ],
            "incomeReportDocument": [
                78
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantFilter": {
            "applicationStatusIn": [
                97
            ],
            "buildingIdIn": [
                324
            ],
            "createdAtGte": [
                712
            ],
            "createdAtLte": [
                712
            ],
            "organizationId": [
                324
            ],
            "screeningStatusIn": [
                533
            ],
            "unitIdIn": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantGenerateLeaseInput": {
            "amenities": [
                358
            ],
            "concessionOf": [
                305
            ],
            "concessions": [
                206
            ],
            "leaseEndDate": [
                712
            ],
            "leaseStartDate": [
                712
            ],
            "mainApplicantId": [
                324
            ],
            "monthlyRent": [
                305
            ],
            "monthsOfConcession": [
                332
            ],
            "pool": [
                358
            ],
            "securityDeposit": [
                305
            ],
            "sendToApplicant": [
                142
            ],
            "thirdPartyGuarantor": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantInvitee": {
            "email": [
                613
            ],
            "firstName": [
                613
            ],
            "lastName": [
                613
            ],
            "middleName": [
                613
            ],
            "relation": [
                613
            ],
            "type": [
                90
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantLeaseTerms": {
            "amenities": [
                357
            ],
            "concessionOf": [
                305
            ],
            "concessions": [
                205
            ],
            "leaseEndDate": [
                712
            ],
            "leaseStartDate": [
                712
            ],
            "monthlyRent": [
                305
            ],
            "monthsOfConcession": [
                332
            ],
            "pool": [
                357
            ],
            "securityDeposit": [
                305
            ],
            "thirdPartyGuarantor": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantOrderBy": {},
        "ApplicantPet": {
            "age": [
                305
            ],
            "breed": [
                613
            ],
            "color": [
                613
            ],
            "name": [
                613
            ],
            "sex": [
                460
            ],
            "type": [
                461
            ],
            "weight": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantPublicData": {
            "applicantSubmissionStep": [
                88
            ],
            "coApplicants": [
                190
            ],
            "email": [
                613
            ],
            "firstName": [
                613
            ],
            "id": [
                324
            ],
            "lastName": [
                613
            ],
            "mainApplicant": [
                381
            ],
            "middleName": [
                613
            ],
            "plaidIdvToken": [
                613
            ],
            "unit": [
                716
            ],
            "unitId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantRejectInput": {
            "mainApplicantId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantSubmissionStep": {},
        "ApplicantSummary": {
            "assets": [
                100
            ],
            "credit": [
                213
            ],
            "criminal": [
                215
            ],
            "identity": [
                326
            ],
            "income": [
                330
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantType": {},
        "ApplicantUpdateResponse": {
            "id": [
                324
            ],
            "idvToken": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantsInviteInput": {
            "invitees": [
                82
            ],
            "mainApplicantId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ApplicantsInviteResponse": {
            "failed": [
                613
            ],
            "succeeded": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ApplicationPage": {},
        "ApplicationSettings": {
            "assetsThreshold": [
                305
            ],
            "buildingId": [
                324
            ],
            "consentHTMLTemplate": [
                613
            ],
            "creditScoreThreshold": [
                332
            ],
            "criminalRecordsThreshold": [
                332
            ],
            "id": [
                324
            ],
            "incomeThresholdNumberOfRentMonths": [
                332
            ],
            "isIdentityVerificationRequired": [
                142
            ],
            "organizationId": [
                324
            ],
            "plaidIDVTemplateId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ApplicationSettingsInput": {
            "assetsThreshold": [
                305
            ],
            "consentHTMLTemplate": [
                613
            ],
            "creditScoreThreshold": [
                332
            ],
            "criminalRecordsThreshold": [
                332
            ],
            "incomeThresholdNumberOfRentMonths": [
                332
            ],
            "isIdentityVerificationRequired": [
                142
            ],
            "plaidIDVTemplateId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ApplicationStatus": {},
        "ApplicationUnitUpdateInput": {
            "mainApplicantId": [
                324
            ],
            "unitId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "AssetReportResult": {
            "details": [
                613
            ],
            "status": [
                532
            ],
            "totalAssets": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "AssetReportSummary": {
            "assetsSum": [
                332
            ],
            "minAssetsThreshold": [
                332
            ],
            "status": [
                622
            ],
            "__typename": [
                613
            ]
        },
        "AttachmentsBehavior": {},
        "AttachmentsCategory": {},
        "AttachmentsUploadUrlResponse": {
            "URL": [
                613
            ],
            "key": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AttachmentsUrlCreateInput": {
            "category": [
                102
            ],
            "__typename": [
                613
            ]
        },
        "AttendanceStatus": {},
        "AuthChallenge": {
            "authSession": [
                613
            ],
            "type": [
                764
            ],
            "__typename": [
                613
            ]
        },
        "AuthChallengeCreateInput": {
            "username": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AuthChallengeVerifyInput": {
            "answer": [
                613
            ],
            "authSession": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AuthChallengeVerifyResponse": {
            "token": [
                110
            ],
            "__typename": [
                613
            ]
        },
        "AuthToken": {
            "accessToken": [
                613
            ],
            "refreshToken": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AuthTokenRefreshInput": {
            "originalToken": [
                613
            ],
            "refreshToken": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AuthTokenRefreshResponse": {
            "token": [
                110
            ],
            "__typename": [
                613
            ]
        },
        "AuthenticationMethod": {},
        "AutomationActionDescriptor": {
            "actionParams": [
                116
            ],
            "displayName": [
                613
            ],
            "id": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "AutomationDescriptor": {
            "availableActions": [
                114
            ],
            "displayName": [
                613
            ],
            "id": [
                324
            ],
            "predicateParams": [
                116
            ],
            "ruleType": [
                527
            ],
            "supportedScopes": [
                125
            ],
            "__typename": [
                613
            ]
        },
        "AutomationDescriptorParam": {
            "availableValues": [
                117
            ],
            "displayName": [
                613
            ],
            "id": [
                324
            ],
            "isMandatory": [
                142
            ],
            "maxValue": [
                332
            ],
            "minValue": [
                332
            ],
            "type": [
                118
            ],
            "uiOrder": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "AutomationDescriptorParamOption": {
            "key": [
                613
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AutomationDescriptorParamType": {},
        "AutomationRule": {
            "action": [
                120
            ],
            "automationDescriptorId": [
                324
            ],
            "buildingIds": [
                613
            ],
            "communityIds": [
                613
            ],
            "createdAt": [
                712
            ],
            "createdByUser": [
                721
            ],
            "createdByUserId": [
                324
            ],
            "description": [
                613
            ],
            "id": [
                324
            ],
            "isActive": [
                142
            ],
            "name": [
                613
            ],
            "organizationId": [
                613
            ],
            "predicateParamValues": [
                123
            ],
            "scope": [
                125
            ],
            "stats": [
                126
            ],
            "__typename": [
                613
            ]
        },
        "AutomationRuleAction": {
            "actionDescriptorId": [
                324
            ],
            "actionParams": [
                123
            ],
            "__typename": [
                613
            ]
        },
        "AutomationRuleActionInput": {
            "actionDescriptorId": [
                324
            ],
            "actionParams": [
                124
            ],
            "__typename": [
                613
            ]
        },
        "AutomationRuleInput": {
            "action": [
                121
            ],
            "automationDescriptorId": [
                324
            ],
            "description": [
                613
            ],
            "isActive": [
                142
            ],
            "name": [
                613
            ],
            "predicateParamValues": [
                124
            ],
            "__typename": [
                613
            ]
        },
        "AutomationRuleParamValue": {
            "paramDescriptorId": [
                324
            ],
            "value": [
                58
            ],
            "__typename": [
                613
            ]
        },
        "AutomationRuleParamValueInput": {
            "paramDescriptorId": [
                324
            ],
            "value": [
                58
            ],
            "__typename": [
                613
            ]
        },
        "AutomationRuleScope": {},
        "AutomationRuleStats": {
            "lastWeek": [
                332
            ],
            "total": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "AutomationTriggerHistory": {
            "actionData": [
                613
            ],
            "createdAt": [
                712
            ],
            "eventData": [
                613
            ],
            "id": [
                324
            ],
            "ruleId": [
                324
            ],
            "status": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AutomationTriggerHistoryWhereInput": {
            "createdAtGte": [
                712
            ],
            "ruleId": [
                324
            ],
            "status": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AwayNotice": {
            "awayNoticeServiceReservations": [
                132
            ],
            "createdAt": [
                712
            ],
            "endDate": [
                712
            ],
            "id": [
                324
            ],
            "leaseContract": [
                359
            ],
            "leaseContractId": [
                324
            ],
            "notes": [
                613
            ],
            "organizationId": [
                324
            ],
            "startDate": [
                712
            ],
            "status": [
                136
            ],
            "timeline": [
                713
            ],
            "__typename": [
                613
            ]
        },
        "AwayNoticeCreateInput": {
            "awayNoticeServiceReservations": [
                133
            ],
            "endDate": [
                712
            ],
            "leaseContractId": [
                324
            ],
            "notes": [
                613
            ],
            "startDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "AwayNoticeOrderBy": {},
        "AwayNoticeServiceReservation": {
            "awayNoticeServiceType": [
                613
            ],
            "notes": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AwayNoticeServiceReservationInput": {
            "awayNoticeServiceType": [
                613
            ],
            "notes": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "AwayNoticeSettings": {
            "awayNoticeServiceTypes": [
                613
            ],
            "buildingId": [
                324
            ],
            "id": [
                324
            ],
            "isEnabled": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "AwayNoticeSettingsUpsertInput": {
            "awayNoticeServiceTypes": [
                613
            ],
            "buildingId": [
                324
            ],
            "isEnabled": [
                142
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "AwayNoticeStatus": {},
        "AwayNoticeUpdateInput": {
            "awayNoticeServiceReservations": [
                133
            ],
            "endDate": [
                712
            ],
            "leaseContractId": [
                324
            ],
            "notes": [
                613
            ],
            "startDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "AwayNoticeWhereInput": {
            "endDateLt": [
                712
            ],
            "startDateGt": [
                712
            ],
            "status": [
                136
            ],
            "__typename": [
                613
            ]
        },
        "Bedroom": {
            "askedRent": [
                305
            ],
            "id": [
                324
            ],
            "leaseContracts": [
                359
            ],
            "name": [
                613
            ],
            "realEstateListing": [
                495
            ],
            "squareMeters": [
                332
            ],
            "unit": [
                715
            ],
            "__typename": [
                613
            ]
        },
        "BookingOption": {
            "endTime": [
                712
            ],
            "startTime": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "BookingOptionsWhereInput": {
            "date": [
                712
            ],
            "durationInMinutes": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "Boolean": {},
        "Brand": {
            "buildingsIds": [
                324
            ],
            "colors": [
                144
            ],
            "emailOTPLogo": [
                613
            ],
            "id": [
                324
            ],
            "landLordLogo": [
                613
            ],
            "organizationId": [
                613
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BrandColors": {
            "primary": [
                613
            ],
            "primaryDark": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BrandColorsInput": {
            "primary": [
                613
            ],
            "primaryDark": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BrandCreateInput": {
            "buildingsIds": [
                324
            ],
            "colors": [
                145
            ],
            "emailOTPLogo": [
                613
            ],
            "landLordLogo": [
                613
            ],
            "organizationId": [
                324
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BrandUpdateInput": {
            "buildingsIds": [
                324
            ],
            "colors": [
                145
            ],
            "emailOTPLogo": [
                613
            ],
            "landLordLogo": [
                613
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Broadcast": {
            "attachments": [
                149
            ],
            "audiences": [
                152
            ],
            "category": [
                157
            ],
            "content": [
                158
            ],
            "createdAt": [
                712
            ],
            "createdBy": [
                721
            ],
            "createdByUserId": [
                324
            ],
            "dateSent": [
                712
            ],
            "emailPayload": [
                161
            ],
            "id": [
                324
            ],
            "medium": [
                408
            ],
            "mediums": [
                163
            ],
            "notifications": [
                419,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        418
                    ]
                }
            ],
            "scheduledBroadcast": [
                529
            ],
            "sendStatus": [
                164
            ],
            "stats": [
                167
            ],
            "updatedBy": [
                721
            ],
            "updatedByUserId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastAttachment": {
            "id": [
                324
            ],
            "url": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastAttachmentCreateInput": {
            "type": [
                151
            ],
            "url": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastAttachmentType": {},
        "BroadcastAudience": {
            "audienceParams": [
                154
            ],
            "type": [
                156
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastAudienceInput": {
            "audienceParams": [
                155
            ],
            "type": [
                156
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastAudienceParams": {
            "on_BuildingAudienceParams": [
                173
            ],
            "on_CommunityAudienceParams": [
                193
            ],
            "on_EventAudienceParams": [
                273
            ],
            "on_InterestGroupsAudienceParams": [
                348
            ],
            "on_SpecificUsersAudienceParams": [
                604
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastAudienceParamsInput": {
            "buildingAudienceParams": [
                174
            ],
            "communityAudienceParams": [
                194
            ],
            "eventAudienceParams": [
                274
            ],
            "interestGroupsAudienceParams": [
                769
            ],
            "specificUsersAudienceParams": [
                605
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastAudienceType": {},
        "BroadcastCategory": {},
        "BroadcastContent": {
            "cta": [
                216
            ],
            "deepLink": [
                221
            ],
            "message": [
                613
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastContentInput": {
            "cta": [
                217
            ],
            "deepLink": [
                222
            ],
            "message": [
                613
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastCreateInput": {
            "attachments": [
                150
            ],
            "audiences": [
                153
            ],
            "category": [
                157
            ],
            "content": [
                159
            ],
            "createdByUserId": [
                324
            ],
            "emailPayload": [
                162
            ],
            "hoodId": [
                324
            ],
            "medium": [
                408
            ],
            "mediums": [
                163
            ],
            "organizationId": [
                324
            ],
            "sendStatus": [
                164
            ],
            "sourceType": [
                165
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastEmailPayload": {
            "fromEmail": [
                613
            ],
            "fromName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastEmailPayloadInput": {
            "fromEmail": [
                613
            ],
            "fromName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastMedium": {},
        "BroadcastSendStatus": {},
        "BroadcastSourceType": {},
        "BroadcastStatName": {},
        "BroadcastStatParam": {
            "count": [
                332
            ],
            "rate": [
                613
            ],
            "status": [
                166
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastUpdateInput": {
            "attachments": [
                150
            ],
            "audiences": [
                153
            ],
            "category": [
                157
            ],
            "content": [
                159
            ],
            "dateSent": [
                712
            ],
            "emailPayload": [
                162
            ],
            "medium": [
                408
            ],
            "mediums": [
                163
            ],
            "scheduledBroadcastId": [
                324
            ],
            "sendStatus": [
                164
            ],
            "updatedByUserId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastWhereInput": {
            "communityIds": [
                324
            ],
            "createdAtBetween": [
                219
            ],
            "medium": [
                408
            ],
            "mediums": [
                163
            ],
            "organizationId": [
                324
            ],
            "statusIn": [
                164
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastsList": {
            "items": [
                148
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "BroadcastsOrderBy": {},
        "Building": {
            "address": [
                5
            ],
            "buildingNumber": [
                332
            ],
            "communities": [
                192
            ],
            "community": [
                192
            ],
            "communityId": [
                324
            ],
            "externalId": [
                324
            ],
            "id": [
                324
            ],
            "legalName": [
                613
            ],
            "name": [
                613
            ],
            "paymentReminders": [
                441
            ],
            "pmsAdapterId": [
                324
            ],
            "settings": [
                176
            ],
            "status": [
                178
            ],
            "streetName": [
                613
            ],
            "units": [
                715
            ],
            "__typename": [
                613
            ]
        },
        "BuildingAudienceParams": {
            "buildingIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "BuildingAudienceParamsInput": {
            "buildingIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "BuildingMode": {},
        "BuildingSettings": {
            "amenityReservationTemplateName": [
                613
            ],
            "buildingId": [
                613
            ],
            "calcContractStatusByDate": [
                142
            ],
            "isLocksEnabled": [
                142
            ],
            "isPackagesEnabled": [
                142
            ],
            "isRentPaymentMobileEnabled": [
                142
            ],
            "isRentPaymentSupported": [
                142
            ],
            "isShiftNotesEnabled": [
                142
            ],
            "isVisitorsEnabled": [
                142
            ],
            "paymentPolicyFAQLink": [
                613
            ],
            "paymentPortalLink": [
                613
            ],
            "thermostatAdapterId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BuildingSettingsSetInput": {
            "amenityReservationTemplateName": [
                613
            ],
            "buildingId": [
                613
            ],
            "calcContractStatusByDate": [
                142
            ],
            "isLocksEnabled": [
                142
            ],
            "isPackagesEnabled": [
                142
            ],
            "isRentPaymentMobileEnabled": [
                142
            ],
            "isRentPaymentSupported": [
                142
            ],
            "isShiftNotesEnabled": [
                142
            ],
            "isVisitorsEnabled": [
                142
            ],
            "paymentPolicyFAQLink": [
                613
            ],
            "paymentPortalLink": [
                613
            ],
            "thermostatAdapterId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BuildingStatus": {},
        "BuildingsTaskListSetInput": {
            "buildingIds": [
                324
            ],
            "taskListId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "BuildingsWhereInput": {
            "communityIdsIn": [
                324
            ],
            "externalIds": [
                324
            ],
            "ids": [
                324
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Byline": {
            "count": [
                332
            ],
            "type": [
                182
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "BylineType": {},
        "Calendar": {
            "id": [
                324
            ],
            "intervalDuration": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "CalendarBookingDetailsInput": {
            "slots": [
                185
            ],
            "__typename": [
                613
            ]
        },
        "CalendarTimeSlotInput": {
            "endTime": [
                712
            ],
            "startTime": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "CancellationRequestStatus": {},
        "CashDetailsInput": {
            "receiptNumber": [
                613
            ],
            "staffMemberId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "CheckDetailsInput": {
            "bankName": [
                613
            ],
            "checkDate": [
                712
            ],
            "checkNumber": [
                613
            ],
            "receiptNumber": [
                613
            ],
            "staffMemberId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "CheckInStatus": {},
        "CoApplicantPublicData": {
            "email": [
                613
            ],
            "firstName": [
                613
            ],
            "id": [
                324
            ],
            "lastName": [
                613
            ],
            "middleName": [
                613
            ],
            "type": [
                90
            ],
            "__typename": [
                613
            ]
        },
        "CoApplicantUpdateInput": {
            "SSN": [
                613
            ],
            "applicantId": [
                324
            ],
            "birthDate": [
                712
            ],
            "city": [
                613
            ],
            "firstName": [
                613
            ],
            "lastName": [
                613
            ],
            "middleName": [
                613
            ],
            "phone": [
                613
            ],
            "state": [
                613
            ],
            "street": [
                613
            ],
            "zip": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Community": {
            "buildings": [
                172,
                {
                    "where": [
                        197
                    ]
                }
            ],
            "coordinates": [
                209
            ],
            "country": [
                211
            ],
            "displayName": [
                613
            ],
            "id": [
                324
            ],
            "language": [
                356
            ],
            "name": [
                613
            ],
            "organization": [
                426
            ],
            "organizationId": [
                324
            ],
            "settings": [
                199
            ],
            "state": [
                613
            ],
            "timezone": [
                613
            ],
            "uiMetadata": [
                201
            ],
            "__typename": [
                613
            ]
        },
        "CommunityAudienceParams": {
            "communityIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "CommunityAudienceParamsInput": {
            "communityIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "CommunityBuildings": {
            "buildingsIds": [
                324
            ],
            "communityId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "CommunityBuildingsSetInput": {
            "buildingsIds": [
                324
            ],
            "communityId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "CommunityBuildingsWhereInput": {
            "externalIds": [
                324
            ],
            "ids": [
                324
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "CommunityCreateInput": {
            "coordinates": [
                210
            ],
            "country": [
                211
            ],
            "displayName": [
                613
            ],
            "language": [
                356
            ],
            "organizationId": [
                324
            ],
            "state": [
                613
            ],
            "timezone": [
                613
            ],
            "uiMetadata": [
                202
            ],
            "__typename": [
                613
            ]
        },
        "CommunitySettings": {
            "communityId": [
                324
            ],
            "moderationSlackChannel": [
                613
            ],
            "socialProfileSuspendDurationInMinutes": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "CommunitySettingsSetInput": {
            "communityId": [
                324
            ],
            "emailSender": [
                613
            ],
            "emailTemplate": [
                613
            ],
            "moderationSlackChannel": [
                613
            ],
            "smsPhoneNumberSender": [
                613
            ],
            "socialProfileSuspendDurationInMinutes": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "CommunityUiMetadata": {
            "appOnboardingScreens": [
                68
            ],
            "collateralUrl": [
                613
            ],
            "communityLogoUrl": [
                613
            ],
            "dashboardLogoUrl": [
                613
            ],
            "hideVennLogo": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "CommunityUiMetadataInput": {
            "appOnboardingScreens": [
                69
            ],
            "collateralUrl": [
                613
            ],
            "communityLogoUrl": [
                613
            ],
            "dashboardLogoUrl": [
                613
            ],
            "hideVennLogo": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "CommunityUpdateInput": {
            "coordinates": [
                210
            ],
            "country": [
                211
            ],
            "displayName": [
                613
            ],
            "language": [
                356
            ],
            "state": [
                613
            ],
            "timezone": [
                613
            ],
            "uiMetadata": [
                202
            ],
            "__typename": [
                613
            ]
        },
        "CommunityWhereInput": {
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Concession": {
            "amount": [
                305
            ],
            "month": [
                383
            ],
            "year": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ConcessionInput": {
            "amount": [
                305
            ],
            "month": [
                383
            ],
            "year": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ContractStatus": {},
        "ContractTimeline": {},
        "Coordinate": {
            "latitude": [
                305
            ],
            "latitudeDelta": [
                305
            ],
            "longitude": [
                305
            ],
            "longitudeDelta": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "CoordinateInput": {
            "latitude": [
                305
            ],
            "longitude": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "CountryCode": {},
        "CreditReportResult": {
            "creditScore": [
                332
            ],
            "details": [
                613
            ],
            "status": [
                532
            ],
            "__typename": [
                613
            ]
        },
        "CreditReportSummary": {
            "averageCreditScore": [
                332
            ],
            "minCreditThreshold": [
                332
            ],
            "status": [
                622
            ],
            "__typename": [
                613
            ]
        },
        "CriminalBackgroundReportResult": {
            "details": [
                613
            ],
            "numberOfOffenses": [
                332
            ],
            "status": [
                532
            ],
            "__typename": [
                613
            ]
        },
        "CriminalBackgroundReportSummary": {
            "status": [
                622
            ],
            "sumOfCriminalRecords": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "CtaData": {
            "applicationPage": [
                94
            ],
            "ctaText": [
                613
            ],
            "description": [
                613
            ],
            "entityId": [
                324
            ],
            "params": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "CtaInput": {
            "applicationPage": [
                94
            ],
            "ctaText": [
                613
            ],
            "description": [
                613
            ],
            "entityId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "CustomPaymentOptionsInput": {
            "price": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "DateRangeInput": {
            "end": [
                712
            ],
            "start": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "DayOfWeek": {},
        "DeepLink": {
            "applicationPage": [
                94
            ],
            "description": [
                613
            ],
            "entityId": [
                324
            ],
            "params": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "DeepLinkInput": {
            "applicationPage": [
                94
            ],
            "description": [
                613
            ],
            "entityId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "DeliveryPackage": {
            "additionalInfo": [
                613
            ],
            "arrivedOnDate": [
                712
            ],
            "building": [
                172
            ],
            "buildingId": [
                613
            ],
            "courier": [
                613
            ],
            "id": [
                324
            ],
            "lastReminderDate": [
                712
            ],
            "packageNumber": [
                613
            ],
            "pickUpDate": [
                712
            ],
            "pickUpLocation": [
                613
            ],
            "status": [
                226
            ],
            "unit": [
                715
            ],
            "unitId": [
                613
            ],
            "user": [
                721
            ],
            "userId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "DeliveryPackageCreateInput": {
            "additionalInfo": [
                613
            ],
            "arrivedOnDate": [
                712
            ],
            "courier": [
                613
            ],
            "packageNumber": [
                613
            ],
            "pickUpLocation": [
                613
            ],
            "status": [
                226
            ],
            "unitId": [
                613
            ],
            "userId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "DeliveryPackageOrderBy": {},
        "DeliveryPackageStatus": {},
        "DeliveryPackageUpdateInput": {
            "additionalInfo": [
                613
            ],
            "arrivedOnDate": [
                712
            ],
            "buildingId": [
                613
            ],
            "courier": [
                613
            ],
            "packageNumber": [
                613
            ],
            "pickUpDate": [
                712
            ],
            "pickUpLocation": [
                613
            ],
            "status": [
                226
            ],
            "unitId": [
                613
            ],
            "userId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "DeliveryPackageWhereInput": {
            "id": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "DeliveryPackagesList": {
            "items": [
                223
            ],
            "__typename": [
                613
            ]
        },
        "DeliveryPackagesWhereInput": {
            "arrivedOnDateBetween": [
                219
            ],
            "packageNumberEq": [
                613
            ],
            "statuses": [
                226
            ],
            "statusesIn": [
                226
            ],
            "unitIdIn": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "DepositCashInput": {
            "receiptNumber": [
                613
            ],
            "staffMemberId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "DepositCheckInput": {
            "bankName": [
                613
            ],
            "checkDate": [
                613
            ],
            "checkNumber": [
                613
            ],
            "receiptNumber": [
                613
            ],
            "staffMemberId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "DeviceMode": {},
        "DevicePlatform": {},
        "Discussion": {
            "audienceBuildings": [
                172
            ],
            "authorUser": [
                721
            ],
            "commentsCount": [
                332
            ],
            "id": [
                324
            ],
            "image": [
                613
            ],
            "imageAspectRatio": [
                305
            ],
            "interactingSocialProfiles": [
                589
            ],
            "isLikedByCurrentUser": [
                142
            ],
            "latestActivity": [
                712
            ],
            "likesCount": [
                332
            ],
            "locationLabel": [
                613
            ],
            "publishedAt": [
                712
            ],
            "status": [
                241
            ],
            "survey": [
                638
            ],
            "text": [
                613
            ],
            "type": [
                242
            ],
            "updatedAt": [
                712
            ],
            "wasViewedByCurrentUser": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "DiscussionComment": {
            "authorSocialProfile": [
                589
            ],
            "createdAt": [
                712
            ],
            "id": [
                324
            ],
            "message": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "DiscussionCommentsList": {
            "items": [
                236
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "DiscussionCommentsOrderBy": {},
        "DiscussionCommentsWhereInput": {
            "discussionId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "DiscussionCreateInput": {
            "audienceBuildingIds": [
                324
            ],
            "hoodName": [
                613
            ],
            "image": [
                613
            ],
            "ownerUserId": [
                324
            ],
            "status": [
                241
            ],
            "text": [
                613
            ],
            "type": [
                242
            ],
            "__typename": [
                613
            ]
        },
        "DiscussionPublishStatus": {},
        "DiscussionType": {},
        "DiscussionUpdateInput": {
            "audienceBuildingIds": [
                324
            ],
            "image": [
                613
            ],
            "status": [
                241
            ],
            "text": [
                613
            ],
            "type": [
                242
            ],
            "__typename": [
                613
            ]
        },
        "DiscussionsList": {
            "items": [
                235
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "DiscussionsOrderBy": {},
        "EmailDomain": {
            "allowDomainUsersAsSenders": [
                142
            ],
            "createdAt": [
                712
            ],
            "dkimHost": [
                613
            ],
            "dkimValue": [
                613
            ],
            "domain": [
                613
            ],
            "externalId": [
                324
            ],
            "id": [
                324
            ],
            "organizationId": [
                324
            ],
            "returnPath": [
                613
            ],
            "returnPathCname": [
                613
            ],
            "verifcationStatus": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EmailDomainCreateInput": {
            "allowDomainUsersAsSenders": [
                142
            ],
            "domain": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EmailDomainInput": {
            "domain": [
                613
            ],
            "isVerified": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "EmailDomainSender": {
            "allowedCommunities": [
                192
            ],
            "allowedCommunityIds": [
                324
            ],
            "allowedUserIds": [
                324
            ],
            "allowedUsers": [
                721
            ],
            "createdAt": [
                712
            ],
            "domainId": [
                324
            ],
            "fromEmail": [
                613
            ],
            "fromName": [
                613
            ],
            "id": [
                324
            ],
            "isSystemDefault": [
                142
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "EmailDomainSenderCreateInput": {
            "allowedCommunityIds": [
                324
            ],
            "allowedUserIds": [
                324
            ],
            "fromEmail": [
                613
            ],
            "fromName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EmailDomainSenderUpdateInput": {
            "allowedCommunityIds": [
                324
            ],
            "allowedUserIds": [
                324
            ],
            "fromName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EmailLinkData": {
            "emailAddress": [
                613
            ],
            "subject": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EmailLinkDataInput": {
            "emailAddress": [
                613
            ],
            "subject": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EmailSenderDomain": {
            "domain": [
                613
            ],
            "isVerified": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "EmailSenderForUser": {
            "fromEmail": [
                613
            ],
            "fromName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EmailSettings": {
            "defaultSystemEmail": [
                324
            ],
            "domains": [
                254
            ],
            "endpoint": [
                613
            ],
            "fromEmails": [
                613
            ],
            "id": [
                324
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "EmailSettingsUpdateInput": {
            "domains": [
                248
            ],
            "fromEmails": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EmbeddedApplicationAdapter": {
            "buildingIds": [
                324
            ],
            "endpoint": [
                613
            ],
            "id": [
                324
            ],
            "isEnabled": [
                142
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "settings": [
                535
            ],
            "type": [
                265
            ],
            "__typename": [
                613
            ]
        },
        "EmbeddedApplicationAdapterCreateInput": {
            "buildingIDs": [
                324
            ],
            "endpoint": [
                613
            ],
            "isEnabled": [
                142
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "settings": [
                536
            ],
            "type": [
                265
            ],
            "__typename": [
                613
            ]
        },
        "EmbeddedApplicationAdapterUpdateInput": {
            "buildingIds": [
                324
            ],
            "isEnabled": [
                142
            ],
            "name": [
                613
            ],
            "settings": [
                536
            ],
            "__typename": [
                613
            ]
        },
        "EmbeddedApplicationAdapterWhereInput": {
            "isEnabled": [
                142
            ],
            "typeIn": [
                265
            ],
            "__typename": [
                613
            ]
        },
        "EmbeddedApplicationAuthenticationMethod": {},
        "EmbeddedApplicationCallbackHandlingMethod": {},
        "EmbeddedApplicationSaml2Settings": {
            "acsURL": [
                613
            ],
            "metadataURL": [
                613
            ],
            "relayState": [
                613
            ],
            "userCustomAttributes": [
                355
            ],
            "__typename": [
                613
            ]
        },
        "EmbeddedApplicationType": {},
        "EmbeddedApplicationWindowEventSettings": {
            "errorMessageJsonPath": [
                613
            ],
            "failureJsonPath": [
                613
            ],
            "origin": [
                613
            ],
            "successJsonPath": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EntityType": {},
        "Event": {
            "RVSPs": [
                332
            ],
            "admissionFeeType": [
                7
            ],
            "attendanceCancellable": [
                142
            ],
            "attendees": [
                271,
                {
                    "where": [
                        272
                    ]
                }
            ],
            "audienceBuildingIds": [
                324
            ],
            "audienceBuildings": [
                172
            ],
            "availableTickets": [
                332
            ],
            "category": [
                613
            ],
            "checkIns": [
                332
            ],
            "communities": [
                192
            ],
            "communityIds": [
                324
            ],
            "description": [
                613
            ],
            "endDate": [
                712
            ],
            "hoodId": [
                324
            ],
            "id": [
                324
            ],
            "imageResourceId": [
                324
            ],
            "locationBuilding": [
                172
            ],
            "locationBuildingId": [
                324
            ],
            "maxTickets": [
                332
            ],
            "name": [
                613
            ],
            "ownershipType": [
                278
            ],
            "place": [
                465
            ],
            "placeId": [
                324
            ],
            "publish": [
                142
            ],
            "residentHostIds": [
                324
            ],
            "residentHosts": [
                721
            ],
            "shouldGenerateQRCode": [
                142
            ],
            "startDate": [
                712
            ],
            "statistics": [
                280
            ],
            "status": [
                281
            ],
            "surveyId": [
                324
            ],
            "ticketPrice": [
                305
            ],
            "virtualLink": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EventAttendInput": {
            "eventId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "EventAttendance": {
            "attendanceStatus": [
                105
            ],
            "eventDetails": [
                268
            ],
            "eventId": [
                324
            ],
            "rating": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "EventAttendee": {
            "attendanceStatus": [
                105
            ],
            "eventId": [
                324
            ],
            "id": [
                324
            ],
            "rating": [
                491
            ],
            "user": [
                721
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "EventAttendeeWhereInput": {
            "attendanceStatus": [
                105
            ],
            "attendanceStatusNot": [
                105
            ],
            "__typename": [
                613
            ]
        },
        "EventAudienceParams": {
            "eventId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "EventAudienceParamsInput": {
            "eventId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "EventCancelAttendanceInput": {
            "eventAttendeeId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "EventCheckInWithScanInput": {
            "eventId": [
                324
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "EventCreateInput": {
            "admissionFeeType": [
                7
            ],
            "audienceBuildingIds": [
                324
            ],
            "category": [
                613
            ],
            "communityIds": [
                324
            ],
            "description": [
                613
            ],
            "endDate": [
                712
            ],
            "hoodId": [
                613
            ],
            "imageResourceId": [
                613
            ],
            "maxTickets": [
                332
            ],
            "name": [
                613
            ],
            "ownershipType": [
                278
            ],
            "placeId": [
                324
            ],
            "publish": [
                142
            ],
            "residentHostIds": [
                324
            ],
            "shouldGenerateQRCode": [
                142
            ],
            "startDate": [
                712
            ],
            "ticketPrice": [
                305
            ],
            "virtualLink": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EventOwnershipType": {},
        "EventSearchResult": {
            "buildingName": [
                613
            ],
            "communityId": [
                324
            ],
            "endDate": [
                712
            ],
            "id": [
                324
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "placeName": [
                613
            ],
            "startDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "EventStatistics": {
            "ratingAverage": [
                305
            ],
            "ratingCount": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "EventStatus": {},
        "EventSuggestionImageInput": {
            "format": [
                613
            ],
            "publicId": [
                613
            ],
            "resourceType": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EventSuggestionInput": {
            "communityId": [
                324
            ],
            "date": [
                712
            ],
            "description": [
                613
            ],
            "helpDetails": [
                613
            ],
            "imageResource": [
                282
            ],
            "location": [
                613
            ],
            "locationDetails": [
                613
            ],
            "name": [
                613
            ],
            "organizers": [
                434
            ],
            "timeframe": [
                284
            ],
            "__typename": [
                613
            ]
        },
        "EventSuggestionTimeframe": {},
        "EventUpdateInput": {
            "admissionFeeType": [
                7
            ],
            "audienceBuildingIds": [
                324
            ],
            "category": [
                613
            ],
            "communityIds": [
                324
            ],
            "description": [
                613
            ],
            "endDate": [
                712
            ],
            "hoodId": [
                613
            ],
            "imageResourceId": [
                613
            ],
            "maxTickets": [
                332
            ],
            "name": [
                613
            ],
            "ownershipType": [
                278
            ],
            "placeId": [
                613
            ],
            "publish": [
                142
            ],
            "residentHostIds": [
                324
            ],
            "shouldGenerateQRCode": [
                142
            ],
            "startDate": [
                712
            ],
            "ticketPrice": [
                305
            ],
            "virtualLink": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "EventWhereInput": {
            "buildingIds": [
                324
            ],
            "communityId": [
                324
            ],
            "communityIds": [
                324
            ],
            "hoodId": [
                324
            ],
            "ids": [
                324
            ],
            "searchName": [
                613
            ],
            "startDateGt": [
                712
            ],
            "startDateLt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "EventsList": {
            "items": [
                268
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "EventsOrderBy": {},
        "ExternalAppLinkData": {
            "androidFallbackLink": [
                613
            ],
            "androidLink": [
                613
            ],
            "iosFallbackLink": [
                613
            ],
            "iosLink": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ExternalAppLinkDataInput": {
            "androidFallbackLink": [
                613
            ],
            "androidLink": [
                613
            ],
            "iosFallbackLink": [
                613
            ],
            "iosLink": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "FanMode": {},
        "FeatureFlag": {
            "id": [
                324
            ],
            "key": [
                613
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "FeatureFlagWhereInput": {
            "searchKey": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlide": {
            "audienceBuildings": [
                295
            ],
            "callToAction": [
                296
            ],
            "communityId": [
                324
            ],
            "createdBy": [
                721
            ],
            "createdById": [
                324
            ],
            "description": [
                613
            ],
            "id": [
                324
            ],
            "imageResourceId": [
                613
            ],
            "linkType": [
                372
            ],
            "name": [
                613
            ],
            "priority": [
                332
            ],
            "publishEndDate": [
                712
            ],
            "publishStartDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlideBuildings": {
            "building": [
                172
            ],
            "id": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlideCallToAction": {
            "label": [
                613
            ],
            "link": [
                299
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlideCallToActionInput": {
            "label": [
                613
            ],
            "link": [
                300
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlideCreateInput": {
            "audienceBuildingIds": [
                324
            ],
            "callToAction": [
                297
            ],
            "communityId": [
                324
            ],
            "description": [
                613
            ],
            "image": [
                328
            ],
            "linkType": [
                372
            ],
            "name": [
                613
            ],
            "priority": [
                332
            ],
            "publishEndDate": [
                712
            ],
            "publishStartDate": [
                712
            ],
            "template": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlideLink": {
            "name": [
                613
            ],
            "type": [
                372
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlideLinkInput": {
            "name": [
                613
            ],
            "type": [
                372
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlideList": {
            "items": [
                294
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlideUpdateInput": {
            "audienceBuildingIds": [
                324
            ],
            "callToAction": [
                297
            ],
            "description": [
                613
            ],
            "image": [
                328
            ],
            "linkType": [
                372
            ],
            "name": [
                613
            ],
            "publishEndDate": [
                712
            ],
            "publishStartDate": [
                712
            ],
            "template": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlideWhereInput": {
            "communityIds": [
                324
            ],
            "isActive": [
                142
            ],
            "publishBetween": [
                219
            ],
            "__typename": [
                613
            ]
        },
        "FeaturedSlidesOrderBy": {},
        "Float": {},
        "Gender": {},
        "GeneralInfoPage": {
            "cards": [
                308
            ],
            "communityId": [
                324
            ],
            "createdAt": [
                712
            ],
            "createdBy": [
                721
            ],
            "createdById": [
                324
            ],
            "description": [
                613
            ],
            "id": [
                324
            ],
            "image": [
                327
            ],
            "publish": [
                142
            ],
            "subtitle": [
                613
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "GeneralInfoPageCards": {
            "eventIds": [
                324
            ],
            "events": [
                268
            ],
            "placeIds": [
                324
            ],
            "places": [
                465
            ],
            "serviceIds": [
                324
            ],
            "serviceProductIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "GeneralInfoPageCardsInput": {
            "eventIds": [
                324
            ],
            "placeIds": [
                324
            ],
            "serviceIds": [
                324
            ],
            "serviceProductIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "GeneralInfoPageCreateInput": {
            "cards": [
                309
            ],
            "description": [
                613
            ],
            "image": [
                328
            ],
            "publish": [
                142
            ],
            "subtitle": [
                613
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "GeneralInfoPageList": {
            "items": [
                307
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "GeneralInfoPageUpdateInput": {
            "cards": [
                309
            ],
            "description": [
                613
            ],
            "image": [
                328
            ],
            "publish": [
                142
            ],
            "subtitle": [
                613
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "GeneralInfoPageWhereInput": {
            "communityId": [
                324
            ],
            "freeText": [
                613
            ],
            "publish": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "GeneralInfoPagesOrderBy": {},
        "GuestKey": {
            "key": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "GuestKeyEntry": {
            "id": [
                613
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Hood": {
            "coordinates": [
                58
            ],
            "country": [
                211
            ],
            "displayName": [
                613
            ],
            "id": [
                324
            ],
            "language": [
                613
            ],
            "name": [
                613
            ],
            "portfolio": [
                484
            ],
            "portfolioId": [
                324
            ],
            "state": [
                613
            ],
            "timezone": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "HoodAggregations": {
            "activeSocialProfilesCount": [
                332
            ],
            "newSocialProfilesCount": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "HoodRoot": {
            "aggregations": [
                318
            ],
            "broadcast": [
                148,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "broadcasts": [
                170,
                {
                    "orderBy": [
                        171,
                        "[BroadcastsOrderBy!]"
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        169
                    ]
                }
            ],
            "discussion": [
                235,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "discussionComments": [
                237,
                {
                    "orderBy": [
                        238
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        239,
                        "DiscussionCommentsWhereInput!"
                    ]
                }
            ],
            "discussions": [
                244,
                {
                    "orderBy": [
                        245,
                        "[DiscussionsOrderBy!]"
                    ],
                    "pager": [
                        436
                    ]
                }
            ],
            "discussionsSearchSuggestions": [
                613,
                {
                    "searchTerm": [
                        613,
                        "String!"
                    ]
                }
            ],
            "event": [
                268,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "events": [
                287,
                {
                    "orderBy": [
                        288,
                        "[EventsOrderBy!]"
                    ],
                    "pager": [
                        436,
                        "PageInput!"
                    ],
                    "where": [
                        286
                    ]
                }
            ],
            "featuredSlidesSearchSuggestions": [
                613,
                {
                    "searchTerm": [
                        613,
                        "String!"
                    ]
                }
            ],
            "hoodDetails": [
                317
            ],
            "hoodId": [
                324
            ],
            "hoodSettings": [
                320,
                {
                    "where": [
                        321,
                        "HoodSettingWhereInput!"
                    ]
                }
            ],
            "interestGroup": [
                333,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "interestGroups": [
                349,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        347
                    ]
                }
            ],
            "managersSocialProfiles": [
                589
            ],
            "place": [
                465,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "service": [
                537,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceProduct": [
                538,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "socialProfiles": [
                603,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        602
                    ]
                }
            ],
            "user": [
                721,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "users": [
                745,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        744
                    ]
                }
            ],
            "__typename": [
                613
            ]
        },
        "HoodSetting": {
            "hoodId": [
                324
            ],
            "key": [
                613
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "HoodSettingWhereInput": {
            "key": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "HubMetadata": {
            "background": [
                613
            ],
            "firstTimeWalkMe": [
                142
            ],
            "nickName": [
                613
            ],
            "onboarded": [
                142
            ],
            "picture": [
                613
            ],
            "promoAmenities": [
                142
            ],
            "promoMoveIn": [
                142
            ],
            "renewals": [
                142
            ],
            "roles": [
                613
            ],
            "tShirtSize": [
                613
            ],
            "tasks": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "HubMetadataSetInput": {
            "background": [
                613
            ],
            "firstTimeWalkMe": [
                142
            ],
            "nickName": [
                613
            ],
            "onboarded": [
                142
            ],
            "picture": [
                613
            ],
            "promoAmenities": [
                142
            ],
            "promoMoveIn": [
                142
            ],
            "renewals": [
                142
            ],
            "roles": [
                613
            ],
            "tShirtSize": [
                613
            ],
            "tasks": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ID": {},
        "IdentityVerificationResult": {
            "details": [
                613
            ],
            "identityOverrideTimestamp": [
                712
            ],
            "isIdentityVerified": [
                142
            ],
            "status": [
                532
            ],
            "__typename": [
                613
            ]
        },
        "IdentityVerificationSummary": {
            "areAllIdentitiesVerified": [
                142
            ],
            "status": [
                622
            ],
            "__typename": [
                613
            ]
        },
        "Image": {
            "format": [
                613
            ],
            "resourceId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ImageInput": {
            "resourceId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "IncomeReportResult": {
            "annualIncome": [
                332
            ],
            "details": [
                613
            ],
            "reportType": [
                613
            ],
            "status": [
                532
            ],
            "__typename": [
                613
            ]
        },
        "IncomeReportSummary": {
            "incomeSum": [
                332
            ],
            "minIncomeThreshold": [
                332
            ],
            "numOfSkipped": [
                332
            ],
            "status": [
                622
            ],
            "__typename": [
                613
            ]
        },
        "InitTokenParams": {
            "expiresAt": [
                712
            ],
            "refreshToken": [
                613
            ],
            "token": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Int": {},
        "InterestGroup": {
            "createdAt": [
                712
            ],
            "createdByUser": [
                721
            ],
            "createdByUserId": [
                324
            ],
            "description": [
                613
            ],
            "disabled": [
                142
            ],
            "groupSuggestedByNeighbor": [
                142
            ],
            "id": [
                324
            ],
            "imageUrl": [
                613
            ],
            "isPublic": [
                142
            ],
            "memberCount": [
                332
            ],
            "name": [
                613
            ],
            "socialProfilesPreview": [
                589
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupCreateInput": {
            "description": [
                613
            ],
            "imageUrl": [
                613
            ],
            "isPublic": [
                142
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupInsightCategory": {
            "description": [
                613
            ],
            "id": [
                324
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupInsightCategoryDiscussionWhereInput": {
            "discussionStartedBetween": [
                219
            ],
            "interestGroupIdsIn": [
                324
            ],
            "labels": [
                324
            ],
            "sentiment": [
                345
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupInsightDiscussion": {
            "InterestGroup": [
                333
            ],
            "category": [
                335
            ],
            "categoryId": [
                324
            ],
            "endAt": [
                712
            ],
            "id": [
                324
            ],
            "interestGroupId": [
                324
            ],
            "labels": [
                340
            ],
            "members": [
                341
            ],
            "messageIds": [
                324
            ],
            "messages": [
                343
            ],
            "sentiment": [
                345
            ],
            "shortDescription": [
                613
            ],
            "startAt": [
                712
            ],
            "topic": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupInsightDiscussionWhereInput": {
            "categoryIdsIn": [
                324
            ],
            "discussionStartedBetween": [
                219
            ],
            "interestGroupIdsIn": [
                324
            ],
            "labels": [
                324
            ],
            "sentiment": [
                345
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupInsightSummary": {
            "dateFrom": [
                712
            ],
            "dateTo": [
                712
            ],
            "interestGroupId": [
                324
            ],
            "members": [
                341
            ],
            "messageIds": [
                324
            ],
            "summary": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupLabel": {
            "color": [
                613
            ],
            "icon": [
                613
            ],
            "id": [
                324
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupMember": {
            "user": [
                721
            ],
            "user_id": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupMembersList": {
            "members": [
                341
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupMessage": {
            "communityId": [
                324
            ],
            "id": [
                324
            ],
            "interestGroupId": [
                324
            ],
            "organizationId": [
                324
            ],
            "streamCreatedAt": [
                712
            ],
            "streamUpdatedAt": [
                712
            ],
            "text": [
                613
            ],
            "user": [
                721
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupSearchResult": {
            "communityId": [
                324
            ],
            "id": [
                324
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupSentiment": {},
        "InterestGroupUpdateInput": {
            "description": [
                613
            ],
            "disabled": [
                142
            ],
            "imageUrl": [
                613
            ],
            "isPublic": [
                142
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupWhereInput": {
            "includePrivate": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupsAudienceParams": {
            "interestGroupIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "InterestGroupsList": {
            "items": [
                333
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "InviteStatus": {},
        "JSONW": {},
        "JobItemEntityType": {},
        "JobItemStatus": {},
        "JobItemStatusCount": {
            "count": [
                332
            ],
            "status": [
                670
            ],
            "__typename": [
                613
            ]
        },
        "KeyValueParam": {
            "key": [
                613
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Language": {},
        "LeaseAmenity": {
            "access": [
                142
            ],
            "amount": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "LeaseAmenityInput": {
            "access": [
                142
            ],
            "amount": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "LeaseContract": {
            "activeTaskLists": [
                732,
                {
                    "where": [
                        362,
                        "LeaseContractTaskListWhereInput!"
                    ]
                }
            ],
            "bedroom": [
                139
            ],
            "bedroomId": [
                613
            ],
            "contractTimeline": [
                208
            ],
            "endDate": [
                712
            ],
            "externalId": [
                324
            ],
            "hood": [
                317
            ],
            "hoodId": [
                613
            ],
            "id": [
                324
            ],
            "isDraft": [
                142
            ],
            "isTerminatedEarly": [
                142
            ],
            "lateFeeApplicable": [
                142
            ],
            "lateFeeGracePeriod": [
                332
            ],
            "ledger": [
                366
            ],
            "metadata": [
                58
            ],
            "moveInDate": [
                712
            ],
            "moveOutDate": [
                712
            ],
            "moveOutReason": [
                392
            ],
            "originalEndDate": [
                712
            ],
            "pmsStatus": [
                613
            ],
            "renewalScore": [
                500
            ],
            "renewalSurveyAssignment": [
                501
            ],
            "rent": [
                305
            ],
            "securityDepositAmount": [
                305
            ],
            "startDate": [
                712
            ],
            "status": [
                207
            ],
            "unit": [
                715
            ],
            "unitId": [
                613
            ],
            "user": [
                721
            ],
            "userId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "LeaseContractCreateInput": {
            "endDate": [
                712
            ],
            "organizationId": [
                324
            ],
            "startDate": [
                712
            ],
            "unitId": [
                324
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "LeaseContractOrderBy": {},
        "LeaseContractTaskListWhereInput": {
            "type": [
                689
            ],
            "__typename": [
                613
            ]
        },
        "LeaseContractWhereInput": {
            "buildingIds": [
                324
            ],
            "contractTimeline": [
                208
            ],
            "isDraft": [
                142
            ],
            "isPrimary": [
                142
            ],
            "statusIn": [
                207
            ],
            "userIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "LeaseContractsList": {
            "items": [
                359
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "LeaseEndRange": {
            "end": [
                712
            ],
            "start": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "LeaseLedger": {
            "BalanceDue": [
                305
            ],
            "charges": [
                367
            ],
            "payments": [
                368
            ],
            "totalUnpaidCharges": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "LeaseLedgerCharge": {
            "amount": [
                305
            ],
            "amountPaid": [
                305
            ],
            "code": [
                613
            ],
            "date": [
                712
            ],
            "description": [
                613
            ],
            "id": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "LeaseLedgerPayment": {
            "amount": [
                305
            ],
            "id": [
                324
            ],
            "paidAt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "LeaseRecommendationStatus": {},
        "LeaseRentRange": {
            "end": [
                305
            ],
            "start": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "LegacySettings": {
            "cancelOrderEnabled": [
                142
            ],
            "cancelOrderUserId": [
                613
            ],
            "customCommunityGuidelinesObjectsArray": [
                613
            ],
            "dashboardAdditionalOptions": [
                613
            ],
            "enableMaintenanceChat": [
                142
            ],
            "hasCustomCommunityGuidelines": [
                142
            ],
            "immediatePaymentEnabled": [
                142
            ],
            "landlordImage": [
                613
            ],
            "locksEnabled": [
                142
            ],
            "locksProvider": [
                613
            ],
            "locksSharingEnabled": [
                142
            ],
            "locksSupportPhone": [
                613
            ],
            "maintenanceUserID": [
                613
            ],
            "mobileSettingsIsDemoHood": [
                142
            ],
            "mobileSettingsNewAppDisplayName": [
                613
            ],
            "mobileSettingsNewAppLink": [
                613
            ],
            "mobileSettingsNewAppModalEnabled": [
                142
            ],
            "moveInSettingsAdditionalInterestedParty": [
                613
            ],
            "moveInSettingsEnabled": [
                142
            ],
            "moveInSettingsEnabledUsers": [
                613
            ],
            "moveInSettingsUseHardCodedTemplate": [
                142
            ],
            "packagesEnabled": [
                142
            ],
            "paymentInvoicesEnabled": [
                142
            ],
            "paymentProviderUrl": [
                613
            ],
            "preventUsersFromPostingInFeed": [
                142
            ],
            "supportDescriptionOptionalEnabled": [
                142
            ],
            "supportEntryNotesMandatoryEnabled": [
                142
            ],
            "supportProvider": [
                613
            ],
            "supportSubjectOptionalEnabled": [
                142
            ],
            "supportTicketCovidQuestionsEnabled": [
                142
            ],
            "supportTicketEnabled": [
                142
            ],
            "supportTicketEntryNoteEnabled": [
                142
            ],
            "supportUserId": [
                613
            ],
            "walletEnabled": [
                142
            ],
            "yardiCommentsEmailAddress": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "LinkType": {},
        "LockCredentials": {
            "credentials": [
                613
            ],
            "provider": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "LockProvider": {
            "adapterId": [
                613
            ],
            "authenticationMethod": [
                113
            ],
            "initTokenParams": [
                331
            ],
            "isBluetoothEnabled": [
                142
            ],
            "isGuestAccessEnabled": [
                142
            ],
            "oAuthParams": [
                421
            ],
            "provider": [
                613
            ],
            "providerSpecificParams": [
                355
            ],
            "__typename": [
                613
            ]
        },
        "LockProviderInput": {
            "accessToken": [
                613
            ],
            "adapterId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "LockProviderKey": {
            "adapterId": [
                613
            ],
            "id": [
                613
            ],
            "isSharable": [
                142
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "LockProviderKeysInput": {
            "lockProvidersInput": [
                375
            ],
            "__typename": [
                613
            ]
        },
        "LockProviderType": {},
        "Long": {},
        "MainApplicantCreateInput": {
            "SSN": [
                613
            ],
            "birthDate": [
                712
            ],
            "buildingId": [
                324
            ],
            "city": [
                613
            ],
            "email": [
                613
            ],
            "firstName": [
                613
            ],
            "lastName": [
                613
            ],
            "middleName": [
                613
            ],
            "organizationId": [
                324
            ],
            "pets": [
                85
            ],
            "phone": [
                613
            ],
            "state": [
                613
            ],
            "street": [
                613
            ],
            "unitId": [
                324
            ],
            "zip": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "MainApplicantPublicData": {
            "email": [
                613
            ],
            "firstName": [
                613
            ],
            "id": [
                324
            ],
            "lastName": [
                613
            ],
            "middleName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ManagedCommunitiesWhereInput": {
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "Month": {},
        "MoveInFunnelRecord": {
            "leaseContract": [
                359
            ],
            "leaseContractId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "MoveInFunnelRecordList": {
            "pageInfo": [
                435
            ],
            "records": [
                384
            ],
            "__typename": [
                613
            ]
        },
        "MoveInFunnelStatus": {},
        "MoveInFunnelWhereInput": {
            "buildingIds": [
                324
            ],
            "communityIds": [
                324
            ],
            "leaseStartDateBetween": [
                219
            ],
            "organizationId": [
                324
            ],
            "status": [
                386
            ],
            "__typename": [
                613
            ]
        },
        "MoveInReconcileInput": {
            "communityId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "MoveInSettings": {
            "additionalInterestedParty": [
                142
            ],
            "buildingId": [
                613
            ],
            "disableDaysAfterMoveInDate": [
                332
            ],
            "enableDaysBeforeMoveInDate": [
                332
            ],
            "enabledLeaseStatuses": [
                207
            ],
            "enabledScreeningStatuses": [
                613
            ],
            "enabledUsers": [
                613
            ],
            "isEnabled": [
                142
            ],
            "openForRoommates": [
                142
            ],
            "organizationId": [
                613
            ],
            "useHardCodedTemplate": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "MoveInSettingsCreateInput": {
            "additionalInterestedParty": [
                142
            ],
            "disableDaysAfterMoveInDate": [
                332
            ],
            "enableDaysBeforeMoveInDate": [
                332
            ],
            "enabledLeaseStatuses": [
                207
            ],
            "enabledScreeningStatuses": [
                613
            ],
            "enabledUsers": [
                613
            ],
            "isEnabled": [
                142
            ],
            "openForRoommates": [
                142
            ],
            "useHardCodedTemplate": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "MoveInSettingsUpdateInput": {
            "additionalInterestedParty": [
                142
            ],
            "disableDaysAfterMoveInDate": [
                332
            ],
            "enableDaysBeforeMoveInDate": [
                332
            ],
            "enabledLeaseStatuses": [
                207
            ],
            "enabledScreeningStatuses": [
                613
            ],
            "enabledUsers": [
                613
            ],
            "isEnabled": [
                142
            ],
            "openForRoommates": [
                142
            ],
            "useHardCodedTemplate": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "MoveOutReason": {},
        "Mutation": {
            "ShiftNoteClose": [
                142,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "UserTaskListUpdate": [
                736,
                {
                    "data": [
                        735,
                        "UserTaskListUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "UserTaskUpdate": [
                740,
                {
                    "data": [
                        741,
                        "UserTaskUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "adapterCreate": [
                0,
                {
                    "data": [
                        1,
                        "AdapterDataInput!"
                    ]
                }
            ],
            "adapterUpdate": [
                0,
                {
                    "data": [
                        1,
                        "AdapterDataInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityCalendarCreate": [
                12,
                {
                    "data": [
                        15,
                        "AmenityCalendarCreateInput!"
                    ]
                }
            ],
            "amenityCalendarUnavailabilityDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityCalendarUnavailabilitySet": [
                142,
                {
                    "data": [
                        763,
                        "amenityCalendarUnavailabilitySetInput!"
                    ]
                }
            ],
            "amenityCalendarUpdate": [
                16,
                {
                    "data": [
                        21,
                        "AmenityCalendarUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityCancellationRequestDeny": [
                32,
                {
                    "data": [
                        23,
                        "AmenityCancellationDenialInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityCreate": [
                12,
                {
                    "data": [
                        24,
                        "AmenityCreateInput!"
                    ]
                }
            ],
            "amenityReservationApprove": [
                32,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityReservationCancel": [
                32,
                {
                    "data": [
                        34,
                        "AmenityReservationCancelInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityReservationCancelAndRefund": [
                32,
                {
                    "data": [
                        33,
                        "AmenityReservationCancelAndRefundInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityReservationCancelMany": [
                332,
                {
                    "data": [
                        34,
                        "AmenityReservationCancelInput!"
                    ],
                    "where": [
                        22,
                        "AmenityCancelManyWhereInput!"
                    ]
                }
            ],
            "amenityReservationCreate": [
                32,
                {
                    "data": [
                        35,
                        "AmenityReservationCreateInput!"
                    ]
                }
            ],
            "amenityReservationCustomCreate": [
                32,
                {
                    "data": [
                        36,
                        "AmenityReservationCustomCreateInput!"
                    ]
                }
            ],
            "amenityReservationDeny": [
                32,
                {
                    "data": [
                        37,
                        "AmenityReservationDenialInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityReservationDepositCharge": [
                32,
                {
                    "data": [
                        38,
                        "AmenityReservationDepositChargeInput!"
                    ]
                }
            ],
            "amenityReservationDepositCreate": [
                32,
                {
                    "data": [
                        40,
                        "AmenityReservationDepositTakeInput!"
                    ]
                }
            ],
            "amenityReservationDepositRelease": [
                32,
                {
                    "data": [
                        39,
                        "AmenityReservationDepositReleaseInput!"
                    ]
                }
            ],
            "amenityUpdate": [
                12,
                {
                    "data": [
                        57,
                        "AmenityUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "appDashboardLinkCreate": [
                59,
                {
                    "data": [
                        61,
                        "AppDashboardLinkCreateInput!"
                    ]
                }
            ],
            "appDashboardLinkDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "appDashboardLinkOrder": [
                142,
                {
                    "data": [
                        62,
                        "[AppDashboardLinkOrderInput!]!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "appDashboardLinkUpdate": [
                59,
                {
                    "data": [
                        64,
                        "AppDashboardLinkUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicantConsentCreate": [
                142,
                {
                    "applicantId": [
                        324,
                        "ID!"
                    ],
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "signature": [
                        613,
                        "String!"
                    ]
                }
            ],
            "applicantCreate": [
                77,
                {
                    "input": [
                        380,
                        "MainApplicantCreateInput!"
                    ]
                }
            ],
            "applicantGenerateLease": [
                142,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        81,
                        "ApplicantGenerateLeaseInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicantReject": [
                142,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        87,
                        "ApplicantRejectInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicantScreeningReRun": [
                142,
                {
                    "applicantId": [
                        324,
                        "ID!"
                    ],
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicantStripeCheckoutCreate": [
                614,
                {
                    "applicantId": [
                        324,
                        "ID!"
                    ],
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicantsInvite": [
                93,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        92,
                        "ApplicantsInviteInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicationSettingsUpdate": [
                95,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        96,
                        "ApplicationSettingsInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicationUnitUpdate": [
                142,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        98,
                        "ApplicationUnitUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "authChallengeCreate": [
                106,
                {
                    "data": [
                        107,
                        "AuthChallengeCreateInput!"
                    ]
                }
            ],
            "authChallengeVerify": [
                109,
                {
                    "data": [
                        108,
                        "AuthChallengeVerifyInput!"
                    ]
                }
            ],
            "authTokenRefresh": [
                112,
                {
                    "data": [
                        111,
                        "AuthTokenRefreshInput!"
                    ]
                }
            ],
            "automationRuleCreate": [
                119,
                {
                    "data": [
                        122,
                        "AutomationRuleInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "automationRuleDelete": [
                142,
                {
                    "organizationID": [
                        324,
                        "ID!"
                    ],
                    "ruleId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "automationRuleSet": [
                119,
                {
                    "data": [
                        122,
                        "AutomationRuleInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "ruleId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "awayNoticeApprove": [
                129,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "awayNoticeCreate": [
                129,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        130,
                        "AwayNoticeCreateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "awayNoticeSettingsUpsert": [
                134,
                {
                    "data": [
                        135,
                        "AwayNoticeSettingsUpsertInput!"
                    ]
                }
            ],
            "brandCreate": [
                143,
                {
                    "data": [
                        146,
                        "BrandCreateInput!"
                    ]
                }
            ],
            "brandDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "brandUpdate": [
                143,
                {
                    "data": [
                        147,
                        "BrandUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "broadcastCreate": [
                148,
                {
                    "data": [
                        160,
                        "BroadcastCreateInput!"
                    ]
                }
            ],
            "broadcastDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "broadcastSend": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "broadcastUpdate": [
                148,
                {
                    "data": [
                        168,
                        "BroadcastUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "buildingCommunitySet": [
                172,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "buildingSettingsSet": [
                176,
                {
                    "data": [
                        177,
                        "BuildingSettingsSetInput!"
                    ]
                }
            ],
            "buildingUnitStatusSet": [
                172,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "status": [
                        717,
                        "UnitStatus!"
                    ]
                }
            ],
            "buildingsAutomationRuleCreate": [
                119,
                {
                    "buildingIds": [
                        324,
                        "[ID!]!"
                    ],
                    "data": [
                        122,
                        "AutomationRuleInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "buildingsTaskListSet": [
                172,
                {
                    "data": [
                        179,
                        "BuildingsTaskListSetInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "chatMessageDelete": [
                142,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "messageId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "coApplicantUpdate": [
                91,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        191,
                        "CoApplicantUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "communitiesAutomationRuleCreate": [
                119,
                {
                    "communityIds": [
                        324,
                        "[ID!]!"
                    ],
                    "data": [
                        122,
                        "AutomationRuleInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "communityBuildingsSet": [
                195,
                {
                    "data": [
                        196,
                        "CommunityBuildingsSetInput!"
                    ]
                }
            ],
            "communityCreate": [
                192,
                {
                    "data": [
                        198,
                        "CommunityCreateInput!"
                    ]
                }
            ],
            "communitySettingsSet": [
                199,
                {
                    "data": [
                        200,
                        "CommunitySettingsSetInput!"
                    ]
                }
            ],
            "communityUpdate": [
                192,
                {
                    "data": [
                        203,
                        "CommunityUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "createRenewalSurveyAssignment": [
                501,
                {
                    "input": [
                        502,
                        "RenewalSurveyAssignmentCreateInput!"
                    ]
                }
            ],
            "deliveryPackageCreate": [
                223,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        224,
                        "DeliveryPackageCreateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "deliveryPackageDelete": [
                142,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        228,
                        "DeliveryPackageWhereInput!"
                    ]
                }
            ],
            "deliveryPackagePickupReminderSend": [
                142,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "deliveryPackageUpdate": [
                223,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        227,
                        "DeliveryPackageUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        228,
                        "DeliveryPackageWhereInput!"
                    ]
                }
            ],
            "emailDomainCreate": [
                246,
                {
                    "data": [
                        247,
                        "EmailDomainCreateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailDomainSenderCreate": [
                249,
                {
                    "domainId": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        250,
                        "EmailDomainSenderCreateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailDomainSenderUpdate": [
                249,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        251,
                        "EmailDomainSenderUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailDomainVerify": [
                246,
                {
                    "domainId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailSetAllowDomainUsersAsSenders": [
                142,
                {
                    "allowDomainUsersAsSenders": [
                        142,
                        "Boolean!"
                    ],
                    "domainId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailSettingsDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailSettingsUpdate": [
                256,
                {
                    "data": [
                        257,
                        "EmailSettingsUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailSystemDefaultSet": [
                142,
                {
                    "domainSenderId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "embeddedApplicationAdapterCreate": [
                258,
                {
                    "data": [
                        259,
                        "EmbeddedApplicationAdapterCreateInput!"
                    ]
                }
            ],
            "embeddedApplicationAdapterUpdate": [
                258,
                {
                    "adapterId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        260,
                        "EmbeddedApplicationAdapterUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "eventCheckin": [
                142,
                {
                    "communityId": [
                        324
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "userIds": [
                        324,
                        "[ID!]!"
                    ]
                }
            ],
            "eventCreate": [
                268,
                {
                    "data": [
                        277,
                        "EventCreateInput!"
                    ]
                }
            ],
            "eventDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "eventUpdate": [
                268,
                {
                    "data": [
                        285,
                        "EventUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "externalLockProviderGroupCreate": [
                765,
                {
                    "data": [
                        766,
                        "externalLockProviderGroupCreateInput!"
                    ]
                }
            ],
            "externalLockProviderGroupDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "externalLockProviderGroupUpdate": [
                765,
                {
                    "data": [
                        768,
                        "externalLockProviderGroupUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "featuredSlideCreate": [
                294,
                {
                    "data": [
                        298,
                        "FeaturedSlideCreateInput!"
                    ]
                }
            ],
            "featuredSlideDelete": [
                142,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "featuredSlideUpdate": [
                294,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        302,
                        "FeaturedSlideUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "generalInfoPageCreate": [
                307,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        310,
                        "GeneralInfoPageCreateInput!"
                    ]
                }
            ],
            "generalInfoPageDelete": [
                142,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "generalInfoPageUpdate": [
                307,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        312,
                        "GeneralInfoPageUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "interestGroupCreate": [
                333,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        334,
                        "InterestGroupCreateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "interestGroupDelete": [
                142,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "interestGroupUpdate": [
                333,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        346,
                        "InterestGroupUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "leaseContractCreate": [
                359,
                {
                    "data": [
                        360,
                        "LeaseContractCreateInput!"
                    ]
                }
            ],
            "leaseContractTerminate": [
                359,
                {
                    "endDate": [
                        712,
                        "Time!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "me": [
                394
            ],
            "moveInReconcile": [
                142,
                {
                    "data": [
                        388,
                        "MoveInReconcileInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "moveInSettingsCreate": [
                389,
                {
                    "buildingId": [
                        324
                    ],
                    "data": [
                        390,
                        "MoveInSettingsCreateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "moveInSettingsUpdate": [
                389,
                {
                    "buildingId": [
                        324
                    ],
                    "data": [
                        391,
                        "MoveInSettingsUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "notificationSnoozeCreate": [
                410,
                {
                    "data": [
                        411,
                        "NotificationSnoozeCreateInput!"
                    ]
                }
            ],
            "notificationSnoozeUpdate": [
                410,
                {
                    "data": [
                        412,
                        "NotificationSnoozeUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "notificationsUpdateMany": [
                332,
                {
                    "data": [
                        417,
                        "NotificationUpdateInput!"
                    ],
                    "where": [
                        418,
                        "NotificationWhereInput!"
                    ]
                }
            ],
            "organizationAutomationRuleCreate": [
                119,
                {
                    "data": [
                        122,
                        "AutomationRuleInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "organizationCreate": [
                426,
                {
                    "data": [
                        427,
                        "OrganizationCreateInput!"
                    ]
                }
            ],
            "organizationMemberInvite": [
                428,
                {
                    "data": [
                        429,
                        "OrganizationMemberInviteInput!"
                    ]
                }
            ],
            "organizationMemberUpdate": [
                428,
                {
                    "data": [
                        431,
                        "OrganizationMemberUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "organizationUpdate": [
                426,
                {
                    "data": [
                        433,
                        "OrganizationUpdateInput!"
                    ]
                }
            ],
            "overrideIdentityResult": [
                142,
                {
                    "applicantId": [
                        324,
                        "ID!"
                    ],
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "verified": [
                        142,
                        "Boolean!"
                    ]
                }
            ],
            "paymentReminderDelete": [
                142,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "paymentReminderSet": [
                441,
                {
                    "data": [
                        444,
                        "PaymentRemindersSetInput!"
                    ]
                }
            ],
            "periodicSurveyAssignmentsBatchCreate": [
                639,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "periodicSurveySettingsCreate": [
                449,
                {
                    "data": [
                        450,
                        "PeriodicSurveySettingsCreateInput!"
                    ]
                }
            ],
            "periodicSurveySettingsSet": [
                449,
                {
                    "data": [
                        451,
                        "PeriodicSurveySettingsSetInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "plaidAccessTokenCreate": [
                142,
                {
                    "input": [
                        466,
                        "PlaidAccessTokenCreateInput!"
                    ]
                }
            ],
            "plaidIncomeSkip": [
                142,
                {
                    "applicantId": [
                        324,
                        "ID!"
                    ],
                    "applicantSubmissionStep": [
                        88,
                        "ApplicantSubmissionStep!"
                    ],
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "plaidLinkTokenCreate": [
                468,
                {
                    "input": [
                        467,
                        "PlaidLinkTokenCreateInput!"
                    ]
                }
            ],
            "pmsAdapterCreate": [
                470,
                {
                    "data": [
                        471,
                        "PmsAdapterCreateInput!"
                    ]
                }
            ],
            "pmsAdapterTest": [
                472,
                {
                    "data": [
                        471,
                        "PmsAdapterCreateInput!"
                    ]
                }
            ],
            "pmsAdapterUpdate": [
                470,
                {
                    "adapterId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        473,
                        "PmsAdapterUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "pmsSyncJobCreate": [
                474,
                {
                    "data": [
                        475,
                        "PmsSyncJobCreateInput!"
                    ]
                }
            ],
            "reputationCreate": [
                508,
                {
                    "data": [
                        509,
                        "ReputationCreateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "reputationDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "reputationUpdate": [
                508,
                {
                    "data": [
                        510,
                        "ReputationUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "residentCreate": [
                514,
                {
                    "data": [
                        515,
                        "ResidentCreateInput!"
                    ]
                }
            ],
            "scheduledBroadcastCreate": [
                529,
                {
                    "data": [
                        530,
                        "ScheduledBroadcastCreateInput!"
                    ]
                }
            ],
            "scheduledBroadcastUpdate": [
                529,
                {
                    "data": [
                        531,
                        "ScheduledBroadcastUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestCancel": [
                629,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestCategorySet": [
                624,
                {
                    "categoryId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        545,
                        "ServiceRequestCategorySetInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestCreate": [
                551,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        547,
                        "ServiceRequestCreateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestSettingsCreate": [
                560,
                {
                    "buildingId": [
                        324
                    ],
                    "data": [
                        561,
                        "ServiceRequestsSettingsInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestSettingsSet": [
                560,
                {
                    "buildingId": [
                        324
                    ],
                    "data": [
                        562,
                        "ServiceRequestsSettingsSetInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestSettingsSurveyIdSet": [
                560,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "surveyId": [
                        613
                    ]
                }
            ],
            "serviceRequestSettingsUpdate": [
                560,
                {
                    "buildingId": [
                        324
                    ],
                    "data": [
                        561,
                        "ServiceRequestsSettingsInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestUpdate": [
                629,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        554,
                        "ServiceRequestUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestsAdapterCreate": [
                556,
                {
                    "data": [
                        557,
                        "ServiceRequestsAdapterCreateInput!"
                    ]
                }
            ],
            "serviceRequestsAdapterDelete": [
                142,
                {
                    "adapterId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestsAdapterSetStatusMapping": [
                556,
                {
                    "adapterId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "statusMapping": [
                        612,
                        "StatusMappings!"
                    ]
                }
            ],
            "serviceRequestsAdapterTest": [
                558,
                {
                    "data": [
                        557,
                        "ServiceRequestsAdapterCreateInput!"
                    ]
                }
            ],
            "serviceRequestsAdapterUpdate": [
                556,
                {
                    "adapterId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        559,
                        "ServiceRequestsAdapterUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestsSetIsEnabled": [
                560,
                {
                    "buildingId": [
                        324
                    ],
                    "isEnabled": [
                        142,
                        "Boolean!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "setApplicantSubmissionStep": [
                142,
                {
                    "applicantId": [
                        324,
                        "ID!"
                    ],
                    "applicantSubmissionStep": [
                        88,
                        "ApplicantSubmissionStep!"
                    ],
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "setAwayNoticeAsArchived": [
                142,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "setShiftNotesAsArchived": [
                142,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "setVisitorCheckInStatus": [
                749,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "keyNumber": [
                        613
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "setVisitorCheckOutStatus": [
                749,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "shiftNoteCreate": [
                567,
                {
                    "data": [
                        568,
                        "ShiftNoteCreateInput!"
                    ]
                }
            ],
            "shiftNoteUpdate": [
                567,
                {
                    "data": [
                        571,
                        "ShiftNoteUpdateInput!"
                    ]
                }
            ],
            "smartLockAdapterCreate": [
                574,
                {
                    "data": [
                        575,
                        "SmartLockAdapterCreateInput!"
                    ]
                }
            ],
            "smartLockAdapterDelete": [
                142,
                {
                    "adapterId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "smartLockAdapterUpdate": [
                574,
                {
                    "adapterId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        576,
                        "SmartLockAdapterUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "socialProfileSuspend": [
                142,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "suspendDurationInMinutes": [
                        332
                    ],
                    "userId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "staffTaskCreate": [
                606,
                {
                    "data": [
                        608,
                        "StaffTaskCreateInput!"
                    ]
                }
            ],
            "staffTaskDelete": [
                142,
                {
                    "where": [
                        611,
                        "StaffTaskWhereUniqueInput!"
                    ]
                }
            ],
            "staffTaskUpdate": [
                606,
                {
                    "data": [
                        609,
                        "StaffTaskUpdateInput!"
                    ],
                    "where": [
                        611,
                        "StaffTaskWhereUniqueInput!"
                    ]
                }
            ],
            "stripeConnectedAccountAddExisting": [
                615,
                {
                    "data": [
                        616,
                        "StripeConnectedAccountAddExistingInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "stripeConnectedAccountCreate": [
                615,
                {
                    "data": [
                        617,
                        "StripeConnectedAccountCreateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "stripeConnectedAccountSessionCreate": [
                618,
                {
                    "accountId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "stripeConnectedAccountUpdate": [
                615,
                {
                    "data": [
                        619,
                        "StripeConnectedAccountUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "supportTicketCommentCreate": [
                630,
                {
                    "data": [
                        631,
                        "SupportTicketCommentCreateInput!"
                    ]
                }
            ],
            "supportTicketCreate": [
                629,
                {
                    "data": [
                        632,
                        "SupportTicketCreateInput!"
                    ]
                }
            ],
            "surveyCreate": [
                638,
                {
                    "data": [
                        647,
                        "SurveyCreateInput!"
                    ]
                }
            ],
            "surveyQuestionUpdate": [
                648,
                {
                    "data": [
                        655,
                        "SurveyQuestionUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "surveyResponseSubmit": [
                639,
                {
                    "data": [
                        656,
                        "SurveyResponseSubmitInput!"
                    ]
                }
            ],
            "surveyUpdate": [
                638,
                {
                    "data": [
                        660,
                        "SurveyUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "syncJobCreate": [
                663,
                {
                    "data": [
                        664,
                        "SyncJobCreateInput!"
                    ]
                }
            ],
            "syncJobDownloadExport": [
                666,
                {
                    "data": [
                        665,
                        "SyncJobDownloadExportInput!"
                    ]
                }
            ],
            "syncJobPrepareExport": [
                142,
                {
                    "data": [
                        672,
                        "SyncJobPrepareExportInput!"
                    ]
                }
            ],
            "taskCreate": [
                679,
                {
                    "data": [
                        680,
                        "TaskCreateInput!"
                    ]
                }
            ],
            "taskListCreate": [
                681,
                {
                    "data": [
                        683,
                        "TaskListCreateInput!"
                    ]
                }
            ],
            "taskListCreateFrom": [
                681,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        682,
                        "TaskListCreateFromInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "taskListDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "taskListTaskCreate": [
                684,
                {
                    "data": [
                        685,
                        "TaskListTaskCreateInput!"
                    ]
                }
            ],
            "taskListTaskDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "taskListTaskReorder": [
                684,
                {
                    "data": [
                        686,
                        "TaskListTaskReorderInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "taskListTaskUpdate": [
                684,
                {
                    "data": [
                        687,
                        "TaskListTaskUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "taskListUpdate": [
                681,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        690,
                        "TaskListUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "taskUpdate": [
                679,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        696,
                        "TaskUpdateInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "teamBuildingPermissionsSet": [
                142,
                {
                    "data": [
                        701,
                        "TeamBuildingPermissionsSetInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "teamBuildingsPermissionsAdd": [
                142,
                {
                    "data": [
                        702,
                        "TeamBuildingsPermissionsAddInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "teamCreate": [
                699,
                {
                    "data": [
                        703,
                        "TeamCreateInput!"
                    ]
                }
            ],
            "teamDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "teamUpdate": [
                699,
                {
                    "data": [
                        705,
                        "TeamUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "unitIsAcceptingApplicationsSet": [
                715,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "isAcceptingApplications": [
                        142,
                        "Boolean!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "unitIds": [
                        324,
                        "[ID!]!"
                    ]
                }
            ],
            "unitStatusSet": [
                715,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "status": [
                        717,
                        "UnitStatus!"
                    ],
                    "unitIds": [
                        324,
                        "[ID!]!"
                    ]
                }
            ],
            "userLoginApiKey": [
                725,
                {
                    "data": [
                        724,
                        "UserLoginApiKeyInput!"
                    ]
                }
            ],
            "visitorCancel": [
                749,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "visitorCreate": [
                749,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        752,
                        "VisitorUpsertInput!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "visitorUpdate": [
                749,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "data": [
                        752,
                        "VisitorUpsertInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "visitorsSettingsUpsert": [
                754,
                {
                    "data": [
                        755,
                        "VisitorsSettingsUpsertInput!"
                    ]
                }
            ],
            "whiteLabelCreate": [
                759,
                {
                    "data": [
                        761,
                        "WhiteLabelCreateInput!"
                    ]
                }
            ],
            "whiteLabelDelete": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "whiteLabelUpdate": [
                759,
                {
                    "data": [
                        762,
                        "WhiteLabelUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "__typename": [
                613
            ]
        },
        "MutationUserRoot": {
            "ActiveLeaseContracts": [
                359
            ],
            "MyAwayNoticeCreate": [
                129,
                {
                    "data": [
                        130,
                        "AwayNoticeCreateInput!"
                    ]
                }
            ],
            "MyAwayNoticeSetArchived": [
                142,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyAwayNoticeUpdate": [
                129,
                {
                    "data": [
                        137,
                        "AwayNoticeUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyDeliveryPackageStatusSet": [
                142,
                {
                    "packageId": [
                        613,
                        "String!"
                    ],
                    "pickUpDate": [
                        712
                    ],
                    "status": [
                        226,
                        "DeliveryPackageStatus!"
                    ]
                }
            ],
            "MyEmbeddedAppAuthenticatedURLGenerate": [
                516,
                {
                    "EmbeddedAppID": [
                        324,
                        "ID!"
                    ],
                    "embeddedAppRoute": [
                        613
                    ],
                    "leaseContractID": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyEventAttend": [
                271,
                {
                    "eventId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyEventCheckIn": [
                271,
                {
                    "eventId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyEventCheckInWithScan": [
                271,
                {
                    "data": [
                        276,
                        "EventCheckInWithScanInput!"
                    ]
                }
            ],
            "MyEventCheckinCancel": [
                271,
                {
                    "eventId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyEventRatingSet": [
                268,
                {
                    "eventId": [
                        324,
                        "ID!"
                    ],
                    "rating": [
                        332,
                        "Int!"
                    ],
                    "review": [
                        613
                    ]
                }
            ],
            "MyEventSuggest": [
                268,
                {
                    "data": [
                        283,
                        "EventSuggestionInput!"
                    ]
                }
            ],
            "MyEventUnattend": [
                271,
                {
                    "eventId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyFirstLoginSet": [
                142
            ],
            "MyGenerateAttachmentsUploadUrl": [
                103,
                {
                    "data": [
                        104,
                        "AttachmentsUrlCreateInput!"
                    ]
                }
            ],
            "MyHubMetadataSet": [
                721,
                {
                    "data": [
                        323,
                        "HubMetadataSetInput!"
                    ]
                }
            ],
            "MyLockProvidersInitializeAccess": [
                374
            ],
            "MyMobileLastVisit": [
                142
            ],
            "MyNotificationSendYourselfTest": [
                405,
                {
                    "data": [
                        160,
                        "BroadcastCreateInput!"
                    ]
                }
            ],
            "MyNotificationUpdate": [
                405,
                {
                    "NotificationData": [
                        417,
                        "NotificationUpdateInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyPersonalInformationUpdate": [
                721,
                {
                    "data": [
                        456,
                        "PersonalInformationUpdateInput!"
                    ]
                }
            ],
            "MyPushNotificationTokenRegister": [
                142,
                {
                    "data": [
                        499,
                        "RegisterUserDeviceInput!"
                    ]
                }
            ],
            "MyPushNotificationTokenUnRegister": [
                142,
                {
                    "data": [
                        719,
                        "UnregisterUserDeviceInput!"
                    ]
                }
            ],
            "MyRenewalSurveyAssignmentUpdate": [
                501,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        396,
                        "MyRenewalSurveyAssignmentUpdateInput!"
                    ]
                }
            ],
            "MyReservationCancel": [
                32,
                {
                    "data": [
                        34,
                        "AmenityReservationCancelInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyReservationCancelMany": [
                332,
                {
                    "data": [
                        34,
                        "AmenityReservationCancelInput!"
                    ],
                    "where": [
                        22,
                        "AmenityCancelManyWhereInput!"
                    ]
                }
            ],
            "MyReservationCheckout": [
                398,
                {
                    "data": [
                        397,
                        "MyReservationCheckoutInput!"
                    ]
                }
            ],
            "MyReservationCreate": [
                32,
                {
                    "data": [
                        54,
                        "AmenityReservationUserCreateInput!"
                    ]
                }
            ],
            "MyReservationPay": [
                32,
                {
                    "data": [
                        45,
                        "AmenityReservationPayInput!"
                    ]
                }
            ],
            "MyReservationRequestCancel": [
                32,
                {
                    "data": [
                        47,
                        "AmenityReservationRequestCancelInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyServiceRequestCancel": [
                629,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyServiceRequestCommentCreate": [
                546,
                {
                    "data": [
                        399,
                        "MyServiceRequestCommentCreateInput!"
                    ]
                }
            ],
            "MyServiceRequestUpdate": [
                629,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "input": [
                        400,
                        "MyServiceRequestUpdateInput!"
                    ]
                }
            ],
            "MySmartLockGuestKeyGenerate": [
                315,
                {
                    "data": [
                        577,
                        "SmartLockGuestKeyGenerateInput!"
                    ]
                }
            ],
            "MySmartLockOAuthTokenGenerate": [
                331,
                {
                    "data": [
                        578,
                        "SmartLockOAuthTokenGenerateInput!"
                    ]
                }
            ],
            "MySmartLockOAuthTokenRefresh": [
                331,
                {
                    "data": [
                        579,
                        "SmartLockOAuthTokenRefreshInput!"
                    ]
                }
            ],
            "MySmartLockRegisterWebhook": [
                142,
                {
                    "data": [
                        580,
                        "SmartLockWebhookRegisterInput!"
                    ]
                }
            ],
            "MySmartLockUnRegisterWebhook": [
                142,
                {
                    "data": [
                        581,
                        "SmartLockWebhookUnregisterInput!"
                    ]
                }
            ],
            "MySurveyResponseSubmit": [
                639,
                {
                    "data": [
                        657,
                        "SurveyResponseUserSubmitInput!"
                    ]
                }
            ],
            "MySurveySnooze": [
                639,
                {
                    "assignmentId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyTermsAndConditionsAccept": [
                142,
                {
                    "whiteLabelId": [
                        613,
                        "String!"
                    ]
                }
            ],
            "MyThermostatSet": [
                142,
                {
                    "data": [
                        709
                    ]
                }
            ],
            "MyThermostatSetState": [
                142,
                {
                    "data": [
                        710
                    ]
                }
            ],
            "MyUserTaskListSubmit": [
                734,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyUserTaskStatusUpdate": [
                740,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "status": [
                        739,
                        "UserTaskStatus!"
                    ]
                }
            ],
            "MyUserTaskSubmit": [
                402,
                {
                    "data": [
                        401,
                        "MyUserTaskSubmitInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyVisitorCancel": [
                749,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "MyVisitorCreate": [
                749,
                {
                    "data": [
                        752,
                        "VisitorUpsertInput!"
                    ]
                }
            ],
            "MyVisitorUpdate": [
                749,
                {
                    "data": [
                        752,
                        "VisitorUpsertInput!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "UserId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "MyLeaseContractWhereInput": {
            "statusIn": [
                207
            ],
            "__typename": [
                613
            ]
        },
        "MyRenewalSurveyAssignmentUpdateInput": {
            "comment": [
                613
            ],
            "response": [
                503
            ],
            "__typename": [
                613
            ]
        },
        "MyReservationCheckoutInput": {
            "amenityId": [
                613
            ],
            "cancelUrl": [
                613
            ],
            "paymentMethod": [
                439
            ],
            "reservationId": [
                613
            ],
            "successUrl": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "MyReservationCheckoutResponse": {
            "checkoutPageUrl": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "MyServiceRequestCommentCreateInput": {
            "body": [
                613
            ],
            "fileKeys": [
                613
            ],
            "ticketId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "MyServiceRequestUpdateInput": {
            "ccRoommatesToTicket": [
                142
            ],
            "description": [
                613
            ],
            "entryNote": [
                613
            ],
            "hasPet": [
                142
            ],
            "permissionToEnterRoom": [
                142
            ],
            "problemValue": [
                613
            ],
            "subject": [
                613
            ],
            "supportCategoryId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "MyUserTaskSubmitInput": {
            "responses": [
                738
            ],
            "status": [
                8
            ],
            "__typename": [
                613
            ]
        },
        "MyUserTaskSubmitResponse": {
            "id": [
                324
            ],
            "responses": [
                737
            ],
            "userTaskListStatus": [
                733
            ],
            "userTaskStatus": [
                739
            ],
            "__typename": [
                613
            ]
        },
        "NeighborsList": {
            "items": [
                589
            ],
            "pageInfo": [
                435
            ],
            "seed": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "NewDiscussionAddedSubscription": {
            "hoodId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "Notification": {
            "broadcast": [
                148
            ],
            "category": [
                406
            ],
            "clickedOnCta": [
                142
            ],
            "createdAt": [
                712
            ],
            "createdBy": [
                721
            ],
            "createdByUserId": [
                324
            ],
            "cta": [
                216
            ],
            "deepLink": [
                221
            ],
            "id": [
                324
            ],
            "message": [
                613
            ],
            "recipientStatus": [
                409
            ],
            "sourceType": [
                613
            ],
            "status": [
                415
            ],
            "title": [
                613
            ],
            "toUser": [
                721
            ],
            "toUserId": [
                324
            ],
            "visibleToUser": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "NotificationCategory": {
            "createdAt": [
                712
            ],
            "description": [
                613
            ],
            "id": [
                324
            ],
            "isMandatory": [
                142
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "NotificationCategoryWhereInput": {
            "isMandatory": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "NotificationMedium": {},
        "NotificationRecipientStatus": {},
        "NotificationSnooze": {
            "expiration": [
                712
            ],
            "id": [
                324
            ],
            "notificationCategory": [
                406
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "NotificationSnoozeCreateInput": {
            "enabled": [
                142
            ],
            "expiration": [
                712
            ],
            "medium": [
                408
            ],
            "notificationCategoryId": [
                324
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "NotificationSnoozeUpdateInput": {
            "enabled": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "NotificationSnoozeWhereInput": {
            "enabled": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "NotificationSnoozesList": {
            "items": [
                410
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "NotificationStatus": {},
        "NotificationType": {},
        "NotificationUpdateInput": {
            "clickedOnCta": [
                142
            ],
            "recipientStatus": [
                409
            ],
            "__typename": [
                613
            ]
        },
        "NotificationWhereInput": {
            "createdAtGt": [
                712
            ],
            "medium": [
                408
            ],
            "recipientStatusIn": [
                409
            ],
            "recipientStatusNotIn": [
                409
            ],
            "sourceTypeIn": [
                613
            ],
            "status": [
                415
            ],
            "toUserId": [
                324
            ],
            "toUserSearchKeys": [
                613
            ],
            "type": [
                416
            ],
            "visibleToUser": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "NotificationsList": {
            "items": [
                405
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "NotificationsOrderBy": {},
        "OAuthParams": {
            "apiBaseURL": [
                613
            ],
            "authURL": [
                613
            ],
            "clientId": [
                613
            ],
            "tokenURL": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "OauthSettings": {
            "apiBaseURL": [
                613
            ],
            "authURL": [
                613
            ],
            "clientId": [
                613
            ],
            "clientSecret": [
                613
            ],
            "tokenURL": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "OauthSettingsInput": {
            "apiBaseURL": [
                613
            ],
            "authURL": [
                613
            ],
            "clientId": [
                613
            ],
            "clientSecret": [
                613
            ],
            "tokenURL": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "OrderedActiveUserTaskLists": {
            "userTaskList": [
                732
            ],
            "userTasks": [
                729
            ],
            "__typename": [
                613
            ]
        },
        "OrderedActiveUserTaskListsWhereInput": {
            "communityId": [
                324
            ],
            "type": [
                689
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "Organization": {
            "buildings": [
                172,
                {
                    "where": [
                        180
                    ]
                }
            ],
            "communities": [
                192
            ],
            "id": [
                324
            ],
            "logoUrl": [
                613
            ],
            "name": [
                613
            ],
            "subdomain": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "OrganizationCreateInput": {
            "organizationName": [
                613
            ],
            "user": [
                723
            ],
            "__typename": [
                613
            ]
        },
        "OrganizationMember": {
            "id": [
                324
            ],
            "inviteDate": [
                712
            ],
            "inviteStatus": [
                350
            ],
            "isActive": [
                142
            ],
            "organizationId": [
                324
            ],
            "teams": [
                699
            ],
            "type": [
                430
            ],
            "user": [
                721
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "OrganizationMemberInviteInput": {
            "email": [
                613
            ],
            "firstName": [
                613
            ],
            "isActive": [
                142
            ],
            "lastName": [
                613
            ],
            "mobilePhone": [
                613
            ],
            "organizationId": [
                324
            ],
            "teamIds": [
                324
            ],
            "type": [
                430
            ],
            "__typename": [
                613
            ]
        },
        "OrganizationMemberType": {},
        "OrganizationMemberUpdateInput": {
            "isActive": [
                142
            ],
            "teamIdsSet": [
                324
            ],
            "type": [
                430
            ],
            "__typename": [
                613
            ]
        },
        "OrganizationMembersWhereInput": {
            "searchKey": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "OrganizationUpdateInput": {
            "logoUrl": [
                613
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "OrganizerDetails": {
            "mobilePhone": [
                613
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PageInfo": {
            "totalItems": [
                379
            ],
            "__typename": [
                613
            ]
        },
        "PageInput": {
            "offset": [
                332
            ],
            "size": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "PaymentDetails": {
            "serviceFee": [
                305
            ],
            "totalPrice": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "PaymentDetailsInput": {
            "cashDetails": [
                187
            ],
            "checkDetails": [
                188
            ],
            "paymentMethod": [
                439
            ],
            "__typename": [
                613
            ]
        },
        "PaymentMethod": {},
        "PaymentOption": {
            "icon": [
                613
            ],
            "isEmbeddedPayment": [
                142
            ],
            "isSelfService": [
                142
            ],
            "paymentMethod": [
                439
            ],
            "serviceFee": [
                305
            ],
            "totalPrice": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "PaymentReminders": {
            "lateReminderSchedule": [
                442
            ],
            "reminderSchedule": [
                442
            ],
            "__typename": [
                613
            ]
        },
        "PaymentRemindersSchedule": {
            "dayOfMonth": [
                332
            ],
            "hourOfDay": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "PaymentRemindersScheduleInput": {
            "dayOfMonth": [
                332
            ],
            "hourOfDay": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "PaymentRemindersSetInput": {
            "buildingId": [
                324
            ],
            "lateReminderSchedule": [
                443
            ],
            "reminderSchedule": [
                443
            ],
            "__typename": [
                613
            ]
        },
        "PeriodicSurveyQuestionResponseStats": {
            "count": [
                332
            ],
            "rating": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "PeriodicSurveyQuestionStats": {
            "averageScore": [
                305
            ],
            "questionId": [
                324
            ],
            "responsesStats": [
                445
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PeriodicSurveyReport": {
            "batchId": [
                324
            ],
            "questionsStats": [
                446
            ],
            "surveyStats": [
                452
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PeriodicSurveyReportWhereInput": {
            "surveyBatchId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "PeriodicSurveySettings": {
            "id": [
                324
            ],
            "minResidentialDays": [
                332
            ],
            "nextRunDate": [
                712
            ],
            "organizationId": [
                324
            ],
            "runIntervalDays": [
                332
            ],
            "surveyId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "PeriodicSurveySettingsCreateInput": {
            "firstRunDate": [
                712
            ],
            "minResidentialDays": [
                332
            ],
            "organizationId": [
                324
            ],
            "runIntervalDays": [
                332
            ],
            "surveyId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "PeriodicSurveySettingsSetInput": {
            "id": [
                324
            ],
            "minResidentialDays": [
                332
            ],
            "runIntervalDays": [
                332
            ],
            "surveyId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "PeriodicSurveyStats": {
            "completed": [
                332
            ],
            "opened": [
                332
            ],
            "sent": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "Permission": {},
        "PermittedBuilding": {
            "building": [
                172
            ],
            "buildingId": [
                324
            ],
            "permissions": [
                453
            ],
            "__typename": [
                613
            ]
        },
        "PermittedBuildingsWhereInput": {
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "PersonalInformationUpdateInput": {
            "firstName": [
                613
            ],
            "lastName": [
                613
            ],
            "profilePictureUrl": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Pet": {
            "bio": [
                613
            ],
            "breed": [
                613
            ],
            "id": [
                324
            ],
            "imgUrl": [
                613
            ],
            "name": [
                613
            ],
            "tags": [
                613
            ],
            "type": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PetCreateInput": {
            "bio": [
                613
            ],
            "breed": [
                613
            ],
            "imgUrl": [
                613
            ],
            "name": [
                613
            ],
            "socialProfileId": [
                324
            ],
            "tags": [
                613
            ],
            "type": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PetDetails": {
            "age": [
                305
            ],
            "breed": [
                613
            ],
            "color": [
                613
            ],
            "name": [
                613
            ],
            "sex": [
                613
            ],
            "type": [
                613
            ],
            "weight": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PetSex": {},
        "PetType": {},
        "PetUpdateInput": {
            "bio": [
                613
            ],
            "breed": [
                613
            ],
            "imgUrl": [
                613
            ],
            "name": [
                613
            ],
            "tags": [
                613
            ],
            "type": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PhoneLinkData": {
            "phoneNumber": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PhoneLinkDataInput": {
            "phoneNumber": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Place": {
            "address": [
                5
            ],
            "addressId": [
                324
            ],
            "building": [
                172
            ],
            "buildingId": [
                324
            ],
            "communityId": [
                324
            ],
            "description": [
                613
            ],
            "id": [
                324
            ],
            "name": [
                613
            ],
            "openingHours": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PlaidAccessTokenCreateInput": {
            "applicantId": [
                324
            ],
            "buildingId": [
                324
            ],
            "organizationId": [
                324
            ],
            "publicToken": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PlaidLinkTokenCreateInput": {
            "applicantId": [
                324
            ],
            "buildingId": [
                324
            ],
            "linkType": [
                469
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "PlaidLinkTokenResponse": {
            "linkToken": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PlaidLinkType": {},
        "PmsAdapter": {
            "endpoint": [
                613
            ],
            "id": [
                324
            ],
            "lastFailureSync": [
                474
            ],
            "lastFailureSyncJobId": [
                324
            ],
            "lastSuccessSync": [
                474
            ],
            "lastSuccessSyncJobId": [
                324
            ],
            "lastSyncJob": [
                474
            ],
            "lastSyncJobId": [
                324
            ],
            "name": [
                613
            ],
            "organization": [
                426
            ],
            "organizationId": [
                324
            ],
            "periodicSyncEnabled": [
                142
            ],
            "settings": [
                535
            ],
            "__typename": [
                613
            ]
        },
        "PmsAdapterCreateInput": {
            "endpoint": [
                613
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "periodicSyncEnabled": [
                142
            ],
            "settings": [
                536
            ],
            "__typename": [
                613
            ]
        },
        "PmsAdapterTestResult": {
            "message": [
                613
            ],
            "success": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "PmsAdapterUpdateInput": {
            "endpoint": [
                613
            ],
            "name": [
                613
            ],
            "periodicSyncEnabled": [
                142
            ],
            "settings": [
                536
            ],
            "__typename": [
                613
            ]
        },
        "PmsSyncJob": {
            "adapterId": [
                324
            ],
            "forceUpdate": [
                142
            ],
            "hoodId": [
                324
            ],
            "id": [
                324
            ],
            "items": [
                477,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        478
                    ]
                }
            ],
            "organizationId": [
                324
            ],
            "results": [
                479
            ],
            "startedAt": [
                712
            ],
            "status": [
                480
            ],
            "updatedAt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "PmsSyncJobCreateInput": {
            "adapterId": [
                324
            ],
            "forceUpdate": [
                142
            ],
            "hoodId": [
                324
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "PmsSyncJobItem": {
            "entityData": [
                58
            ],
            "entityId": [
                324
            ],
            "entityType": [
                352
            ],
            "errors": [
                483
            ],
            "id": [
                324
            ],
            "jobId": [
                324
            ],
            "status": [
                353
            ],
            "vennId": [
                324
            ],
            "warnings": [
                483
            ],
            "__typename": [
                613
            ]
        },
        "PmsSyncJobItemList": {
            "items": [
                476
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "PmsSyncJobItemsWhereInput": {
            "statusIn": [
                353
            ],
            "__typename": [
                613
            ]
        },
        "PmsSyncJobResult": {
            "countPerStatus": [
                354
            ],
            "entityType": [
                352
            ],
            "__typename": [
                613
            ]
        },
        "PmsSyncJobStatus": {},
        "PmsSyncJobWhereInput": {
            "adapterId": [
                324
            ],
            "idIn": [
                324
            ],
            "startedBetween": [
                219
            ],
            "statusIn": [
                480
            ],
            "updatedBetween": [
                219
            ],
            "__typename": [
                613
            ]
        },
        "PmsSyncJobsList": {
            "PageInfo": [
                435
            ],
            "items": [
                474
            ],
            "__typename": [
                613
            ]
        },
        "PmsSyncMessage": {
            "code": [
                613
            ],
            "message": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Portfolio": {
            "id": [
                324
            ],
            "name": [
                613
            ],
            "subdomain": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "PortfolioContextFilter": {
            "buildingIds": [
                324
            ],
            "hoodIds": [
                324
            ],
            "portfolioId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "PortfolioContextFilterInput": {
            "buildingIds": [
                324
            ],
            "hoodIds": [
                324
            ],
            "portfolioId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "PortfolioRoot": {
            "contextFilter": [
                485
            ],
            "hoods": [
                319,
                {
                    "ids": [
                        324,
                        "[ID!]"
                    ]
                }
            ],
            "id": [
                324
            ],
            "leaseContracts": [
                364,
                {
                    "orderBy": [
                        361,
                        "[LeaseContractOrderBy!]"
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        363
                    ]
                }
            ],
            "pmsSyncJobs": [
                482,
                {
                    "where": [
                        481
                    ]
                }
            ],
            "portfolioDetails": [
                484
            ],
            "reports": [
                507
            ],
            "residents": [
                523,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        522
                    ]
                }
            ],
            "survey": [
                638,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "surveys": [
                662
            ],
            "__typename": [
                613
            ]
        },
        "PriceByPaymentMethod": {
            "ach": [
                437
            ],
            "creditCard": [
                437
            ],
            "__typename": [
                613
            ]
        },
        "Query": {
            "amenities": [
                9,
                {
                    "orderBy": [
                        10,
                        "[AmenitiesOrderBy]"
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        11
                    ]
                }
            ],
            "amenity": [
                12,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityCalendarUnavailabilities": [
                19,
                {
                    "orderBy": [
                        20,
                        "[AmenityCalendarUnavailabilityOrderBy]"
                    ],
                    "where": [
                        18
                    ]
                }
            ],
            "amenityCalendarUnavailability": [
                19,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityReservation": [
                32,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "amenityReservations": [
                32,
                {
                    "orderBy": [
                        44,
                        "[AmenityReservationOrderBy]"
                    ],
                    "where": [
                        56
                    ]
                }
            ],
            "appDashboardLink": [
                59,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "appDashboardLinks": [
                59,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicant": [
                76,
                {
                    "applicantId": [
                        324,
                        "ID!"
                    ],
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicantConsentPreview": [
                613,
                {
                    "applicantId": [
                        324,
                        "ID!"
                    ],
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicantPublicData": [
                86,
                {
                    "applicantId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "applicants": [
                76,
                {
                    "orderBy": [
                        84,
                        "[ApplicantOrderBy!]"
                    ],
                    "where": [
                        80,
                        "ApplicantFilter!"
                    ]
                }
            ],
            "applicationSettings": [
                95,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "automationDescriptor": [
                115,
                {
                    "automationDescriptorId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "automationDescriptors": [
                115,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "automationRules": [
                119,
                {
                    "communityIds": [
                        324,
                        "[ID!]"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "automationTriggerHistory": [
                127,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        128
                    ]
                }
            ],
            "awayNotice": [
                129,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "awayNoticeSettings": [
                134,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "awayNoticeSettingsList": [
                134,
                {
                    "buildingsIds": [
                        324,
                        "[ID!]!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "awayNotices": [
                129,
                {
                    "buildingIds": [
                        324,
                        "[ID!]!"
                    ],
                    "orderBy": [
                        131
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        138
                    ]
                }
            ],
            "brand": [
                143,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "brands": [
                143,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "broadcast": [
                148,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "broadcasts": [
                170,
                {
                    "orderBy": [
                        171,
                        "[BroadcastsOrderBy!]"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        169,
                        "BroadcastWhereInput!"
                    ]
                }
            ],
            "building": [
                172,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "communities": [
                192,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        204
                    ]
                }
            ],
            "community": [
                192,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "deliveryPackage": [
                223,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        228,
                        "DeliveryPackageWhereInput!"
                    ]
                }
            ],
            "deliveryPackages": [
                229,
                {
                    "buildingIds": [
                        324,
                        "[ID!]!"
                    ],
                    "orderBy": [
                        225
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        230
                    ]
                }
            ],
            "emailDomain": [
                246,
                {
                    "domainId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailDomainSender": [
                249,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailDomainSenders": [
                249,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailDomains": [
                246,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailSendersForUser": [
                255,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "userId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "emailSettings": [
                256,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "embeddedApplicationAdapter": [
                258,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "embeddedApplicationAdapters": [
                258,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        261
                    ]
                }
            ],
            "event": [
                268,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "events": [
                287,
                {
                    "orderBy": [
                        288,
                        "[EventsOrderBy!]"
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        286,
                        "EventWhereInput!"
                    ]
                }
            ],
            "externalLockProviderGroup": [
                765,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "externalLockProviderGroups": [
                767,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "pager": [
                        436
                    ]
                }
            ],
            "featuredSlide": [
                294,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "featuredSlides": [
                301,
                {
                    "orderBy": [
                        304,
                        "FeaturedSlidesOrderBy!"
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        303
                    ]
                }
            ],
            "generalInfoPage": [
                307,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "generalInfoPages": [
                311,
                {
                    "orderBy": [
                        314,
                        "GeneralInfoPagesOrderBy!"
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        313,
                        "GeneralInfoPageWhereInput!"
                    ]
                }
            ],
            "hood": [
                319,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "interestGroup": [
                333,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "interestGroupInsightCategories": [
                335,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "interestGroupInsightDiscussion": [
                337,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "interestGroupInsightDiscussions": [
                337,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        338,
                        "InterestGroupInsightDiscussionWhereInput!"
                    ]
                }
            ],
            "interestGroupInsightSummary": [
                339,
                {
                    "communityId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "startedBetween": [
                        219
                    ]
                }
            ],
            "interestGroupLabels": [
                340,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "interestGroupMembers": [
                342,
                {
                    "interestGroupId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "interestGroups": [
                333,
                {
                    "communityIds": [
                        324,
                        "[ID!]"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "leaseContract": [
                359,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "leaseContracts": [
                364,
                {
                    "orderBy": [
                        361,
                        "[LeaseContractOrderBy!]"
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        363,
                        "LeaseContractWhereInput!"
                    ]
                }
            ],
            "me": [
                727
            ],
            "moveInFunnel": [
                385,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        387,
                        "MoveInFunnelWhereInput!"
                    ]
                }
            ],
            "moveInSettings": [
                389,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "notificationCategories": [
                406,
                {
                    "where": [
                        407
                    ]
                }
            ],
            "orderedActiveUserTaskLists": [
                424,
                {
                    "where": [
                        425,
                        "OrderedActiveUserTaskListsWhereInput!"
                    ]
                }
            ],
            "organization": [
                426,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "organizationLogoUrl": [
                613,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "organizationMember": [
                428,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "organizationMembers": [
                428,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        432
                    ]
                }
            ],
            "organizations": [
                426
            ],
            "periodicSurveyReport": [
                447,
                {
                    "buildingIds": [
                        324,
                        "[ID!]!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        448,
                        "PeriodicSurveyReportWhereInput!"
                    ]
                }
            ],
            "periodicSurveySettings": [
                449,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "plaidVerifiedPreLinkAccountWithIncome": [
                142,
                {
                    "linkToken": [
                        613,
                        "String!"
                    ]
                }
            ],
            "pmsAdapter": [
                470,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "pmsAdapters": [
                470,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "pmsSyncJob": [
                474,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "pmsSyncJobs": [
                482,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        481,
                        "PmsSyncJobWhereInput!"
                    ]
                }
            ],
            "portfolio": [
                487,
                {
                    "contextFilter": [
                        486,
                        "PortfolioContextFilterInput!"
                    ]
                }
            ],
            "portfolios": [
                484
            ],
            "reputation": [
                508,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "reputations": [
                508,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "residentRenewalRiskScore": [
                500,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "residents": [
                523,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        522,
                        "ResidentWhereInput!"
                    ]
                }
            ],
            "residentsUpForRenewal": [
                500,
                {
                    "buildingIds": [
                        324,
                        "[ID!]!"
                    ],
                    "filters": [
                        524
                    ],
                    "orderBy": [
                        519
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "searchEvents": [
                279,
                {
                    "communityIds": [
                        324,
                        "[ID!]!"
                    ],
                    "limit": [
                        332,
                        "Int!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "searchKey": [
                        613,
                        "String!"
                    ]
                }
            ],
            "searchInterestGroups": [
                344,
                {
                    "communityIds": [
                        324,
                        "[ID!]!"
                    ],
                    "limit": [
                        332,
                        "Int!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "searchKey": [
                        613,
                        "String!"
                    ]
                }
            ],
            "searchResidents": [
                520,
                {
                    "communityIds": [
                        324,
                        "[ID!]!"
                    ],
                    "limit": [
                        332,
                        "Int!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "searchKey": [
                        613,
                        "String!"
                    ]
                }
            ],
            "serviceRequestAdapters": [
                556,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestAttachments": [
                544,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "serviceRequestTicketId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestSettings": [
                560,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequests": [
                548,
                {
                    "buildingIds": [
                        324,
                        "[ID!]"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        566,
                        "ServiceRequestsWhereInput!"
                    ]
                }
            ],
            "serviceRequestsAdapter": [
                556,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestsAdapterDefaultStatusMapping": [
                549,
                {
                    "adapterId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestsCategories": [
                624,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "shiftNote": [
                567,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "shiftNotes": [
                567,
                {
                    "orderBy": [
                        569
                    ],
                    "where": [
                        572,
                        "ShiftNoteWhereInput!"
                    ]
                }
            ],
            "smartLockAdapter": [
                574,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "smartLockAdapters": [
                574,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "staffTask": [
                606,
                {
                    "where": [
                        611,
                        "StaffTaskWhereUniqueInput!"
                    ]
                }
            ],
            "staffTasks": [
                606,
                {
                    "where": [
                        610
                    ]
                }
            ],
            "stripeConnectedAccounts": [
                615,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "survey": [
                638,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "surveyQuestionResponseData": [
                364,
                {
                    "buildingsIds": [
                        324,
                        "[ID!]!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        652
                    ]
                }
            ],
            "surveys": [
                662,
                {
                    "where": [
                        661,
                        "SurveyWhereInput!"
                    ]
                }
            ],
            "surveysBatches": [
                645,
                {
                    "buildingIds": [
                        324,
                        "[ID!]!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        644
                    ]
                }
            ],
            "syncJob": [
                663,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "syncJobs": [
                677,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        676,
                        "SyncJobWhereInput!"
                    ]
                }
            ],
            "task": [
                679,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "taskList": [
                681,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "taskListOverview": [
                692,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        693,
                        "TaskListsOverviewWhereInput!"
                    ]
                }
            ],
            "taskListTask": [
                684,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "taskListTasks": [
                684,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        688,
                        "TaskListTaskWhereInput!"
                    ]
                }
            ],
            "taskLists": [
                681,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        691,
                        "TaskListWhereInput!"
                    ]
                }
            ],
            "tasks": [
                679,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        697,
                        "TaskWhereInput!"
                    ]
                }
            ],
            "team": [
                699,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "teamBuildingPermissions": [
                700,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "teamId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "teamBuildingPermissionsList": [
                700,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "teamId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "teams": [
                699,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        706
                    ]
                }
            ],
            "unit": [
                715,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "unitsAcceptingApplications": [
                716,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "user": [
                721,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "userTask": [
                729,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "userTasks": [
                729,
                {
                    "where": [
                        742,
                        "UserTasksWhereInput!"
                    ]
                }
            ],
            "visitor": [
                749,
                {
                    "buildingId": [
                        324,
                        "ID!"
                    ],
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "visitors": [
                749,
                {
                    "buildingIds": [
                        324,
                        "[ID!]!"
                    ],
                    "orderBy": [
                        750
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ],
                    "where": [
                        753
                    ]
                }
            ],
            "visitorsSettingsList": [
                754,
                {
                    "buildingIds": [
                        324,
                        "[ID!]!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "whiteLabel": [
                759,
                {
                    "id": [
                        324,
                        "ID!"
                    ],
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "whiteLabelConfig": [
                760,
                {
                    "appIdSuffix": [
                        613,
                        "String!"
                    ]
                }
            ],
            "whiteLabels": [
                759,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "__typename": [
                613
            ]
        },
        "QueryParam": {
            "key": [
                613
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Rating": {
            "comment": [
                613
            ],
            "score": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "RealEstateAmenity": {
            "building": [
                172
            ],
            "buildingId": [
                613
            ],
            "category": [
                493
            ],
            "createdAt": [
                712
            ],
            "currentStatus": [
                494
            ],
            "currentStatusDescription": [
                613
            ],
            "deleted": [
                332
            ],
            "deletedAt": [
                712
            ],
            "id": [
                324
            ],
            "unit": [
                715
            ],
            "unitId": [
                613
            ],
            "updatedAt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "RealEstateAmenityCategory": {
            "displayName": [
                613
            ],
            "hood": [
                317
            ],
            "hoodId": [
                613
            ],
            "icon": [
                613
            ],
            "id": [
                324
            ],
            "isPublic": [
                142
            ],
            "manageStatus": [
                142
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "RealEstateAmenityStatus": {},
        "RealEstateListing": {
            "address": [
                5
            ],
            "amenities": [
                492
            ],
            "bedroom": [
                139
            ],
            "bedroomId": [
                613
            ],
            "createdAt": [
                712
            ],
            "deleted": [
                332
            ],
            "deletedAt": [
                712
            ],
            "description": [
                613
            ],
            "featuredListing": [
                142
            ],
            "floorPlan": [
                58
            ],
            "hood": [
                317
            ],
            "hoodName": [
                613
            ],
            "id": [
                324
            ],
            "images": [
                58
            ],
            "listingPrice": [
                305
            ],
            "name": [
                613
            ],
            "nearBySubways": [
                621
            ],
            "promoBadgeTooltip": [
                613
            ],
            "publishInListing": [
                142
            ],
            "relatedListings": [
                58
            ],
            "shortDescription": [
                613
            ],
            "slug": [
                613
            ],
            "unit": [
                715
            ],
            "unitId": [
                613
            ],
            "updatedAt": [
                712
            ],
            "virtualTour": [
                58
            ],
            "__typename": [
                613
            ]
        },
        "RecurrenceEndType": {},
        "RefundDetailsInput": {
            "cashDetails": [
                187
            ],
            "checkDetails": [
                188
            ],
            "refundMethod": [
                498
            ],
            "__typename": [
                613
            ]
        },
        "RefundMethod": {},
        "RegisterUserDeviceInput": {
            "deviceId": [
                613
            ],
            "deviceName": [
                613
            ],
            "devicePlatform": [
                234
            ],
            "deviceToken": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "RenewalRiskScore": {
            "coOccupantsLeaseIds": [
                324
            ],
            "coOccupantsLeases": [
                359
            ],
            "createdAt": [
                712
            ],
            "id": [
                324
            ],
            "mainLeaseContract": [
                359
            ],
            "mainLeaseContractId": [
                324
            ],
            "medianResolutionTime": [
                305
            ],
            "repeatingRequestsCount": [
                332
            ],
            "riskCategory": [
                525
            ],
            "riskScore": [
                305
            ],
            "secondaryLeaseContracts": [
                359
            ],
            "serviceRequestsCount": [
                332
            ],
            "serviceRequestsTopics": [
                613
            ],
            "updatedAt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "RenewalSurveyAssignment": {
            "comment": [
                613
            ],
            "createdAt": [
                712
            ],
            "id": [
                613
            ],
            "leaseContractEndDate": [
                712
            ],
            "leaseContractId": [
                613
            ],
            "response": [
                503
            ],
            "responseDate": [
                712
            ],
            "status": [
                504
            ],
            "__typename": [
                613
            ]
        },
        "RenewalSurveyAssignmentCreateInput": {
            "AutomationRuleId": [
                613
            ],
            "LeaseContractEndDate": [
                712
            ],
            "LeaseContractId": [
                613
            ],
            "ReminderFrequencyInDays": [
                332
            ],
            "Status": [
                504
            ],
            "__typename": [
                613
            ]
        },
        "RenewalSurveyAssignmentResponse": {},
        "RenewalSurveyAssignmentStatus": {},
        "RenewalSurveySettings": {
            "daysBeforeLeaseEnd": [
                332
            ],
            "id": [
                324
            ],
            "isVisibleToUser": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "RentingType": {},
        "ReportsRoot": {
            "contextFilter": [
                485
            ],
            "moveInFunnel": [
                385,
                {
                    "pager": [
                        436,
                        "PageInput!"
                    ],
                    "where": [
                        387,
                        "MoveInFunnelWhereInput!"
                    ]
                }
            ],
            "__typename": [
                613
            ]
        },
        "Reputation": {
            "buildingsIds": [
                324
            ],
            "id": [
                324
            ],
            "organizationId": [
                613
            ],
            "reputationLink": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ReputationCreateInput": {
            "buildingsIds": [
                324
            ],
            "reputationLink": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ReputationUpdateInput": {
            "buildingsIds": [
                324
            ],
            "reputationLink": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ReservationCheckoutResponse": {
            "checkoutPageUrl": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ReservationQuotaFrequency": {},
        "ReservationQuotaType": {},
        "Resident": {
            "amenityReservations": [
                32,
                {
                    "where": [
                        55,
                        "AmenityReservationWhereInput!"
                    ]
                }
            ],
            "email": [
                613
            ],
            "events": [
                268,
                {
                    "where": [
                        518
                    ]
                }
            ],
            "firstName": [
                613
            ],
            "lastName": [
                613
            ],
            "leaseContracts": [
                359
            ],
            "metadata": [
                58
            ],
            "middleName": [
                613
            ],
            "mobilePhone": [
                613
            ],
            "myEvents": [
                287,
                {
                    "orderBy": [
                        288,
                        "[EventsOrderBy!]"
                    ],
                    "where": [
                        286
                    ]
                }
            ],
            "notifications": [
                405
            ],
            "residentSince": [
                712,
                {
                    "organizationId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequests": [
                629,
                {
                    "where": [
                        566,
                        "ServiceRequestsWhereInput!"
                    ]
                }
            ],
            "socialProfile": [
                589
            ],
            "taskLists": [
                732,
                {
                    "where": [
                        521,
                        "ResidentTaskListsWhereInput!"
                    ]
                }
            ],
            "user": [
                721
            ],
            "userId": [
                324
            ],
            "userType": [
                743
            ],
            "__typename": [
                613
            ]
        },
        "ResidentCreateInput": {
            "leaseContractEndDate": [
                712
            ],
            "leaseContractStartDate": [
                712
            ],
            "leaseContractUnitId": [
                324
            ],
            "organizationId": [
                324
            ],
            "userEmail": [
                613
            ],
            "userFirstName": [
                613
            ],
            "userLastName": [
                613
            ],
            "userMobilePhone": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ResidentEmbeddedApplicationDescriptor": {
            "authenticationMethod": [
                262
            ],
            "authenticationSettingsSAML2": [
                264
            ],
            "callbackHandlingMethod": [
                263
            ],
            "callbackHandlingSettingsWindowEvent": [
                266
            ],
            "iframeTitle": [
                613
            ],
            "iframeUrl": [
                613
            ],
            "url": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ResidentEmbeddedApplications": {
            "embeddedAppID": [
                324
            ],
            "endpoint": [
                613
            ],
            "leaseContracts": [
                324
            ],
            "name": [
                613
            ],
            "type": [
                265
            ],
            "__typename": [
                613
            ]
        },
        "ResidentEventsWhereInput": {
            "communityIdIn": [
                324
            ],
            "startDateBetween": [
                219
            ],
            "__typename": [
                613
            ]
        },
        "ResidentRiskSortField": {},
        "ResidentSearchResult": {
            "email": [
                613
            ],
            "firstName": [
                613
            ],
            "lastName": [
                613
            ],
            "mobilePhone": [
                613
            ],
            "unitName": [
                613
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ResidentTaskListsWhereInput": {
            "idIn": [
                324
            ],
            "isActive": [
                142
            ],
            "type": [
                689
            ],
            "__typename": [
                613
            ]
        },
        "ResidentWhereInput": {
            "buildingIdIn": [
                324
            ],
            "communityIdIn": [
                324
            ],
            "completedAppOnboarding": [
                142
            ],
            "contractEndDateBetween": [
                219
            ],
            "contractStartDateBetween": [
                219
            ],
            "contractStatusIn": [
                207
            ],
            "contractTimelineIn": [
                208
            ],
            "isContractPrimary": [
                142
            ],
            "leaseRentRange": [
                370
            ],
            "leaseStatusIn": [
                207
            ],
            "organizationId": [
                324
            ],
            "renewalSurveyResponseIn": [
                503
            ],
            "riskCategoryIn": [
                525
            ],
            "searchKey": [
                613
            ],
            "upForRenewal": [
                142
            ],
            "userIdIn": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ResidentsList": {
            "items": [
                514
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "ResidentsUpForRenewalFilter": {
            "leaseEndRange": [
                365
            ],
            "leaseRentRange": [
                370
            ],
            "riskCategoryIn": [
                525
            ],
            "__typename": [
                613
            ]
        },
        "RiskCategory": {},
        "Role": {
            "hood": [
                317
            ],
            "id": [
                324
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "RuleType": {},
        "SatisfactionScore": {},
        "ScheduledBroadcast": {
            "endDate": [
                712
            ],
            "id": [
                324
            ],
            "nextNotificationDate": [
                712
            ],
            "scheduledRuleCron": [
                613
            ],
            "startDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "ScheduledBroadcastCreateInput": {
            "broadcastTemplateId": [
                324
            ],
            "scheduledRuleCron": [
                613
            ],
            "startDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "ScheduledBroadcastUpdateInput": {
            "endDate": [
                712
            ],
            "scheduledRuleCron": [
                613
            ],
            "startDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "ScreeningCheckStatus": {},
        "ScreeningStatus": {},
        "SectionType": {},
        "SecureKeyValue": {
            "isSecure": [
                142
            ],
            "key": [
                613
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SecureKeyValueInput": {
            "isSecure": [
                142
            ],
            "key": [
                613
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Service": {
            "description": [
                613
            ],
            "id": [
                324
            ],
            "isTermsAndConditionsEnabled": [
                142
            ],
            "name": [
                613
            ],
            "providerName": [
                613
            ],
            "serviceProducts": [
                538,
                {
                    "where": [
                        543
                    ]
                }
            ],
            "termsAndConditions": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ServiceProduct": {
            "actualPrice": [
                305
            ],
            "calendar": [
                183
            ],
            "currentUserSubscriptionContractId": [
                324
            ],
            "description": [
                613
            ],
            "discountedPrice": [
                305
            ],
            "id": [
                324
            ],
            "imageResourceId": [
                613
            ],
            "name": [
                613
            ],
            "pickupLocation": [
                465
            ],
            "price": [
                305
            ],
            "priceUnit": [
                613
            ],
            "service": [
                537
            ],
            "serviceProductType": [
                542
            ],
            "subProductId": [
                324
            ],
            "taxAmount": [
                305
            ],
            "taxRate": [
                698
            ],
            "__typename": [
                613
            ]
        },
        "ServiceProductPurchaseInput": {
            "calendarBookingDetails": [
                184
            ],
            "paymentMethodId": [
                324
            ],
            "serviceProductId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ServiceProductPurchaseResult": {
            "clientSecret": [
                613
            ],
            "isSucceeded": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "ServiceProductSubscribeInput": {
            "externalSubscriptionId": [
                324
            ],
            "serviceProductId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ServiceProductType": {},
        "ServiceProductWhereInput": {
            "published": [
                142
            ],
            "serviceId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestAttachment": {
            "URL": [
                613
            ],
            "contentType": [
                613
            ],
            "fileName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestCategorySetInput": {
            "displayHasPet": [
                142
            ],
            "displayPermissionToEnterRoom": [
                142
            ],
            "published": [
                142
            ],
            "shouldIncludeRoommates": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestComment": {
            "attachments": [
                544
            ],
            "author": [
                721
            ],
            "authorId": [
                324
            ],
            "content": [
                613
            ],
            "id": [
                324
            ],
            "postedAt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestCreateInput": {
            "categoryId": [
                324
            ],
            "ccRoommatesToTicket": [
                142
            ],
            "description": [
                613
            ],
            "entryNote": [
                613
            ],
            "forLeaseContractId": [
                324
            ],
            "forUnitId": [
                324
            ],
            "hasPet": [
                142
            ],
            "metadata": [
                58
            ],
            "permissionToEnterRoom": [
                142
            ],
            "subject": [
                613
            ],
            "target": [
                555
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestList": {
            "items": [
                551
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestStatusMapping": {
            "externalStatus": [
                613
            ],
            "vennStatus": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestStatusMappingInput": {
            "externalStatus": [
                613
            ],
            "vennStatus": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestTicket": {
            "building": [
                172
            ],
            "buildingId": [
                324
            ],
            "category": [
                624
            ],
            "categoryId": [
                324
            ],
            "closedAt": [
                712
            ],
            "comments": [
                546
            ],
            "description": [
                613
            ],
            "entryNote": [
                613
            ],
            "externalId": [
                324
            ],
            "externalStatus": [
                613
            ],
            "forLeaseContract": [
                359
            ],
            "forLeaseContractId": [
                324
            ],
            "forUnit": [
                715
            ],
            "forUnitId": [
                324
            ],
            "hasPet": [
                142
            ],
            "havesDownloadableAttachments": [
                142
            ],
            "id": [
                324
            ],
            "openedAt": [
                712
            ],
            "openingManager": [
                552
            ],
            "openingManagerId": [
                324
            ],
            "permissionToEnterRoom": [
                142
            ],
            "source": [
                633
            ],
            "status": [
                634
            ],
            "subject": [
                613
            ],
            "target": [
                555
            ],
            "technicianNotes": [
                613
            ],
            "timeToResolutionInMinutes": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestTicketOpeningManagerDetails": {
            "email": [
                613
            ],
            "fullName": [
                613
            ],
            "id": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestTicketStatus": {},
        "ServiceRequestUpdateInput": {
            "description": [
                613
            ],
            "entryNote": [
                613
            ],
            "externalStatus": [
                613
            ],
            "hasPet": [
                142
            ],
            "permissionToEnterRoom": [
                142
            ],
            "problemValue": [
                613
            ],
            "subject": [
                613
            ],
            "supportCategoryId": [
                324
            ],
            "technicianNotes": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestedTarget": {},
        "ServiceRequestsAdapter": {
            "endpoint": [
                613
            ],
            "id": [
                324
            ],
            "isPeriodicSyncEnabled": [
                142
            ],
            "lastFailureSync": [
                663
            ],
            "lastFailureSyncJobId": [
                324
            ],
            "lastSuccessSync": [
                663
            ],
            "lastSuccessSyncJobId": [
                324
            ],
            "lastSyncJob": [
                663
            ],
            "lastSyncJobId": [
                324
            ],
            "name": [
                613
            ],
            "organization": [
                426
            ],
            "organizationId": [
                324
            ],
            "settings": [
                535
            ],
            "statusMapping": [
                549
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestsAdapterCreateInput": {
            "endpoint": [
                613
            ],
            "isPeriodicSyncEnabled": [
                142
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "settings": [
                536
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestsAdapterTestResult": {
            "message": [
                613
            ],
            "success": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestsAdapterUpdateInput": {
            "endpoint": [
                613
            ],
            "isPeriodicSyncEnabled": [
                142
            ],
            "name": [
                613
            ],
            "settings": [
                536
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestsSettings": {
            "allowCancellingRequests": [
                142
            ],
            "allowEditingRequests": [
                142
            ],
            "allowedCommentingStatuses": [
                613
            ],
            "areDuplicateRequestsAllowed": [
                142
            ],
            "areEntryNotesRequired": [
                142
            ],
            "buildingId": [
                613
            ],
            "emergencyText": [
                613
            ],
            "hideArchivedRequests": [
                142
            ],
            "isCommentingEnabled": [
                142
            ],
            "isDescriptionRequired": [
                142
            ],
            "isEnabled": [
                142
            ],
            "isHubDashboardEnabled": [
                142
            ],
            "isSubjectRequired": [
                142
            ],
            "organizationId": [
                613
            ],
            "serviceRequestsCategoriesLevelNames": [
                613
            ],
            "shouldDisplayCommentingChatInput": [
                142
            ],
            "shouldDisplayCovidQuestions": [
                142
            ],
            "shouldDisplayEntryNotes": [
                142
            ],
            "shouldDisplayHubDashboardActions": [
                142
            ],
            "shouldDisplaySubject": [
                142
            ],
            "shouldSendEmailOnCreation": [
                142
            ],
            "supportEmail": [
                613
            ],
            "supportPhoneNumber": [
                613
            ],
            "supportedTicketAttachmentsTypes": [
                711
            ],
            "surveyId": [
                613
            ],
            "ticketStatusesNotificationEnabled": [
                613
            ],
            "ticketStatusesNotificationTemplateMapping": [
                563
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestsSettingsInput": {
            "allowCancellingRequests": [
                142
            ],
            "allowEditingRequests": [
                142
            ],
            "allowedCommentingStatuses": [
                613
            ],
            "areDuplicateRequestsAllowed": [
                142
            ],
            "areEntryNotesRequired": [
                142
            ],
            "emergencyText": [
                613
            ],
            "hideArchivedRequests": [
                142
            ],
            "isCommentingEnabled": [
                142
            ],
            "isDescriptionRequired": [
                142
            ],
            "isSubjectRequired": [
                142
            ],
            "shouldDisplayCovidQuestions": [
                142
            ],
            "shouldDisplayEntryNotes": [
                142
            ],
            "shouldSendEmailOnCreation": [
                142
            ],
            "supportEmail": [
                613
            ],
            "supportPhoneNumber": [
                613
            ],
            "surveyId": [
                613
            ],
            "ticketStatusesNotificationEnabled": [
                613
            ],
            "ticketStatusesNotificationTemplateMapping": [
                564
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestsSettingsSetInput": {
            "ShouldDisplayHubDashboardActions": [
                142
            ],
            "allowCancellingRequests": [
                142
            ],
            "allowEditingRequests": [
                142
            ],
            "allowedCommentingStatuses": [
                613
            ],
            "areDuplicateRequestsAllowed": [
                142
            ],
            "areEntryNotesRequired": [
                142
            ],
            "emergencyText": [
                613
            ],
            "hideArchivedRequests": [
                142
            ],
            "isCommentingEnabled": [
                142
            ],
            "isDescriptionRequired": [
                142
            ],
            "isEnabled": [
                142
            ],
            "isHubDashboardEnabled": [
                142
            ],
            "isSubjectRequired": [
                142
            ],
            "shouldDisplayCovidQuestions": [
                142
            ],
            "supportPhoneNumber": [
                613
            ],
            "surveyId": [
                613
            ],
            "ticketStatusesNotificationEnabled": [
                613
            ],
            "ticketStatusesNotificationTemplateMapping": [
                564
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestsSettingsTicketStatusesNotificationTemplateMapping": {
            "notificationContent": [
                770
            ],
            "status": [
                634
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestsSettingsTicketStatusesNotificationTemplateMappingInput": {
            "notificationContent": [
                771
            ],
            "status": [
                634
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestsUploadUrlResponse": {
            "URL": [
                613
            ],
            "key": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ServiceRequestsWhereInput": {
            "categoryIds": [
                324
            ],
            "ids": [
                324
            ],
            "startDateGt": [
                712
            ],
            "statusIn": [
                634
            ],
            "userIdIn": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ShiftNote": {
            "building": [
                172
            ],
            "buildingId": [
                324
            ],
            "createdAt": [
                712
            ],
            "createdBy": [
                721
            ],
            "createdById": [
                324
            ],
            "createdByName": [
                613
            ],
            "id": [
                324
            ],
            "note": [
                613
            ],
            "status": [
                570
            ],
            "__typename": [
                613
            ]
        },
        "ShiftNoteCreateInput": {
            "buildingId": [
                324
            ],
            "note": [
                613
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "ShiftNoteOrderBy": {},
        "ShiftNoteStatus": {},
        "ShiftNoteUpdateInput": {
            "buildingId": [
                324
            ],
            "id": [
                324
            ],
            "note": [
                613
            ],
            "organizationId": [
                324
            ],
            "status": [
                570
            ],
            "__typename": [
                613
            ]
        },
        "ShiftNoteWhereInput": {
            "buildingIds": [
                324
            ],
            "createdAtBT": [
                712
            ],
            "createdAtLT": [
                712
            ],
            "organizationId": [
                324
            ],
            "search": [
                613
            ],
            "statuses": [
                570
            ],
            "__typename": [
                613
            ]
        },
        "SmartLockAccessKeyGenerateInput": {
            "lockProvider": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SmartLockAdapter": {
            "AuthenticationMethod": [
                113
            ],
            "IsGuestAccessEnabled": [
                142
            ],
            "IsMasterKeyEnabled": [
                142
            ],
            "OauthSettings": [
                422
            ],
            "buildingIds": [
                324
            ],
            "buildings": [
                172
            ],
            "endpoint": [
                613
            ],
            "grantAccessByMoveDates": [
                142
            ],
            "grantAccessContractStatuses": [
                207
            ],
            "id": [
                324
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "settings": [
                535
            ],
            "__typename": [
                613
            ]
        },
        "SmartLockAdapterCreateInput": {
            "AuthenticationMethod": [
                113
            ],
            "IsGuestAccessEnabled": [
                142
            ],
            "IsMasterKeyEnabled": [
                142
            ],
            "OauthSettings": [
                423
            ],
            "buildingIds": [
                324
            ],
            "endpoint": [
                613
            ],
            "grantAccessByMoveDates": [
                142
            ],
            "grantAccessContractStatuses": [
                207
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "settings": [
                536
            ],
            "__typename": [
                613
            ]
        },
        "SmartLockAdapterUpdateInput": {
            "AuthenticationMethod": [
                113
            ],
            "IsGuestAccessEnabled": [
                142
            ],
            "IsMasterKeyEnabled": [
                142
            ],
            "OauthSettings": [
                423
            ],
            "buildingIds": [
                324
            ],
            "endpoint": [
                613
            ],
            "grantAccessByMoveDates": [
                142
            ],
            "grantAccessContractStatuses": [
                207
            ],
            "name": [
                613
            ],
            "settings": [
                536
            ],
            "__typename": [
                613
            ]
        },
        "SmartLockGuestKeyGenerateInput": {
            "locks": [
                316
            ],
            "__typename": [
                613
            ]
        },
        "SmartLockOAuthTokenGenerateInput": {
            "adapterId": [
                613
            ],
            "additionalQueryParams": [
                490
            ],
            "authorizationCode": [
                613
            ],
            "codeVerifier": [
                613
            ],
            "redirectURL": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SmartLockOAuthTokenRefreshInput": {
            "adapterId": [
                613
            ],
            "additionalQueryParams": [
                490
            ],
            "refreshToken": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SmartLockWebhookRegisterInput": {
            "adapterId": [
                613
            ],
            "deviceToken": [
                613
            ],
            "metadata": [
                351
            ],
            "__typename": [
                613
            ]
        },
        "SmartLockWebhookUnregisterInput": {
            "adapterId": [
                613
            ],
            "deviceToken": [
                613
            ],
            "metadata": [
                351
            ],
            "__typename": [
                613
            ]
        },
        "SmsLinkData": {
            "body": [
                613
            ],
            "phoneNumber": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SmsLinkDataInput": {
            "body": [
                613
            ],
            "phoneNumber": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SocialInteraction": {
            "fromSocialProfile": [
                589
            ],
            "id": [
                324
            ],
            "toSocialProfile": [
                589
            ],
            "type": [
                586
            ],
            "viewed": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "SocialInteractionCreateInput": {
            "fromUserId": [
                324
            ],
            "toUserId": [
                324
            ],
            "type": [
                586
            ],
            "viewed": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "SocialInteractionType": {},
        "SocialInteractionUpdateInput": {
            "viewed": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "SocialInteractionWhereInput": {
            "fromUserId": [
                324
            ],
            "toUserId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfile": {
            "address": [
                594
            ],
            "biography": [
                594
            ],
            "blockStatus": [
                591
            ],
            "byline": [
                181
            ],
            "callToAction": [
                592
            ],
            "completionPercentage": [
                332
            ],
            "createdAt": [
                712
            ],
            "dateOfBirth": [
                594
            ],
            "firstName": [
                613
            ],
            "id": [
                324
            ],
            "imageId": [
                613
            ],
            "imageUrl": [
                613
            ],
            "interestGroups": [
                349,
                {
                    "pager": [
                        436
                    ]
                }
            ],
            "lastName": [
                613
            ],
            "occupation": [
                594
            ],
            "pets": [
                596
            ],
            "pronouns": [
                599
            ],
            "quote": [
                594
            ],
            "socialLinks": [
                600
            ],
            "socialTags": [
                590
            ],
            "superpowers": [
                594
            ],
            "userId": [
                324
            ],
            "website": [
                594
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfileArrayField": {
            "privacy": [
                597
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfileBlockStatus": {},
        "SocialProfileCallToActionField": {
            "privacy": [
                597
            ],
            "target": [
                613
            ],
            "type": [
                593
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfileCallToActionType": {},
        "SocialProfileField": {
            "privacy": [
                597
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfileImageCreateInput": {
            "fileName": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfilePets": {
            "pets": [
                457
            ],
            "privacy": [
                597
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfilePrivacy": {},
        "SocialProfilePronouns": {},
        "SocialProfilePronounsField": {
            "customPronouns": [
                613
            ],
            "privacy": [
                597
            ],
            "value": [
                598
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfileSocialLinksField": {
            "facebook": [
                594
            ],
            "instagram": [
                594
            ],
            "linkedin": [
                594
            ],
            "youtube": [
                594
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfileUpdateInput": {
            "image": [
                595
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfileWhereInput": {
            "userId_not": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SocialProfilesList": {
            "items": [
                589
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "SpecificUsersAudienceParams": {
            "userIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SpecificUsersAudienceParamsInput": {
            "userIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "StaffTask": {
            "assignees": [
                607
            ],
            "buildingIds": [
                324
            ],
            "createdAt": [
                712
            ],
            "deadline": [
                712
            ],
            "description": [
                613
            ],
            "id": [
                324
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "priority": [
                613
            ],
            "status": [
                613
            ],
            "updatedAt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "StaffTaskAssignee": {
            "user": [
                721
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "StaffTaskCreateInput": {
            "assigneeIds": [
                324
            ],
            "buildingIds": [
                324
            ],
            "deadline": [
                712
            ],
            "description": [
                613
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "priority": [
                613
            ],
            "status": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "StaffTaskUpdateInput": {
            "assigneeIds": [
                324
            ],
            "buildingIds": [
                324
            ],
            "deadline": [
                712
            ],
            "description": [
                613
            ],
            "name": [
                613
            ],
            "priority": [
                613
            ],
            "status": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "StaffTaskWhereInput": {
            "assigneeIds": [
                324
            ],
            "buildingIds": [
                324
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "priority": [
                613
            ],
            "searchKey": [
                613
            ],
            "status": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "StaffTaskWhereUniqueInput": {
            "buildingIds": [
                324
            ],
            "id": [
                324
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "StatusMappings": {
            "mapping": [
                550
            ],
            "__typename": [
                613
            ]
        },
        "String": {},
        "StripeCheckoutSession": {
            "url": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "StripeConnectedAccount": {
            "achFeePercent": [
                305
            ],
            "chargesEnabled": [
                142
            ],
            "creditCardFeePercent": [
                305
            ],
            "email": [
                613
            ],
            "externalId": [
                613
            ],
            "id": [
                324
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "payoutsEnabled": [
                142
            ],
            "requirementsMissing": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "StripeConnectedAccountAddExistingInput": {
            "email": [
                613
            ],
            "externalId": [
                324
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "StripeConnectedAccountCreateInput": {
            "achFeePercent": [
                305
            ],
            "creditCardFeePercent": [
                305
            ],
            "email": [
                613
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "StripeConnectedAccountSession": {
            "clientSecret": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "StripeConnectedAccountUpdateInput": {
            "achFeePercent": [
                305
            ],
            "creditCardFeePercent": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "SubscriptionContract": {
            "id": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SubwayStation": {
            "line": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SummaryStatus": {},
        "SupportAvailabilityInput": {
            "day": [
                626
            ],
            "time": [
                637
            ],
            "__typename": [
                613
            ]
        },
        "SupportCategory": {
            "buildingId": [
                324
            ],
            "clientVisibleName": [
                613
            ],
            "displayHasPet": [
                142
            ],
            "displayPermissionToEnterRoom": [
                142
            ],
            "id": [
                324
            ],
            "isArchived": [
                142
            ],
            "name": [
                613
            ],
            "parent": [
                624
            ],
            "parentId": [
                324
            ],
            "problemValues": [
                613
            ],
            "published": [
                142
            ],
            "requiresScheduling": [
                142
            ],
            "shouldIncludeRoommates": [
                142
            ],
            "type": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SupportCategoryWhereInput": {
            "parentId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SupportDay": {},
        "SupportSatisfactionRating": {
            "comment": [
                613
            ],
            "id": [
                324
            ],
            "score": [
                528
            ],
            "__typename": [
                613
            ]
        },
        "SupportSurveyResponseInput": {
            "comment": [
                613
            ],
            "score": [
                528
            ],
            "supportTicketId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SupportTicket": {
            "category": [
                624
            ],
            "categoryId": [
                324
            ],
            "closedAt": [
                712
            ],
            "comments": [
                546
            ],
            "description": [
                613
            ],
            "entryNote": [
                613
            ],
            "externalId": [
                324
            ],
            "externalStatus": [
                613
            ],
            "hasAttachments": [
                142
            ],
            "hasPet": [
                142
            ],
            "havesDownloadableAttachments": [
                142
            ],
            "id": [
                324
            ],
            "lastUpdated": [
                712
            ],
            "leaseContract": [
                359
            ],
            "leaseContractId": [
                324
            ],
            "openedAt": [
                712
            ],
            "openingManager": [
                552
            ],
            "openingManagerId": [
                324
            ],
            "permissionToEnterRoom": [
                142
            ],
            "source": [
                633
            ],
            "status": [
                634
            ],
            "subject": [
                613
            ],
            "surveyAssignment": [
                639
            ],
            "technicianNotes": [
                613
            ],
            "timeToResolutionInMinutes": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "SupportTicketComment": {
            "attachmentUrls": [
                613
            ],
            "body": [
                613
            ],
            "id": [
                324
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SupportTicketCommentCreateInput": {
            "attachmentUrls": [
                613
            ],
            "body": [
                613
            ],
            "ticketId": [
                324
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SupportTicketCreateInput": {
            "attachmentUrls": [
                613
            ],
            "attachments": [
                720
            ],
            "availability": [
                623
            ],
            "body": [
                613
            ],
            "ccRoommatesToTicket": [
                142
            ],
            "entryNote": [
                613
            ],
            "fileKeys": [
                613
            ],
            "hasPet": [
                142
            ],
            "hoodId": [
                324
            ],
            "metadata": [
                58
            ],
            "permissionToEnterRoom": [
                142
            ],
            "problemValue": [
                613
            ],
            "reporterUserId": [
                324
            ],
            "subject": [
                613
            ],
            "supportCategoryId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SupportTicketSource": {},
        "SupportTicketStatus": {},
        "SupportTicketUpdatesSubscription": {
            "status": [
                634
            ],
            "supportTicketId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SupportTicketWhereInput": {
            "categoryId": [
                324
            ],
            "problemValue": [
                613
            ],
            "statusNotIn": [
                634
            ],
            "__typename": [
                613
            ]
        },
        "SupportTime": {},
        "Survey": {
            "assignmentExpirationDays": [
                332
            ],
            "description": [
                613
            ],
            "id": [
                324
            ],
            "isEnabled": [
                142
            ],
            "isPublic": [
                142
            ],
            "name": [
                613
            ],
            "priority": [
                332
            ],
            "questions": [
                648
            ],
            "snoozeMaxAmount": [
                332
            ],
            "snoozeTTLDays": [
                332
            ],
            "title": [
                613
            ],
            "type": [
                659
            ],
            "__typename": [
                613
            ]
        },
        "SurveyAssignment": {
            "batchId": [
                613
            ],
            "expirationDate": [
                712
            ],
            "id": [
                613
            ],
            "relatedEntityId": [
                613
            ],
            "relatedEntityType": [
                640
            ],
            "relatedLeaseContract": [
                359
            ],
            "relatedSupportTicket": [
                629
            ],
            "snoozeCount": [
                332
            ],
            "snoozeLastDate": [
                712
            ],
            "status": [
                641
            ],
            "survey": [
                638
            ],
            "surveyId": [
                613
            ],
            "user": [
                721
            ],
            "userId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SurveyAssignmentRelatedEntityType": {},
        "SurveyAssignmentStatus": {},
        "SurveyAssignmentUserWhereInput": {
            "batchIdIn": [
                613
            ],
            "relatedEntityIdIn": [
                613
            ],
            "relatedEntityTypeIn": [
                640
            ],
            "statusIn": [
                641
            ],
            "surveyIdIn": [
                613
            ],
            "surveyTypeIn": [
                659
            ],
            "__typename": [
                613
            ]
        },
        "SurveyBatch": {
            "id": [
                324
            ],
            "name": [
                613
            ],
            "survey": [
                638
            ],
            "__typename": [
                613
            ]
        },
        "SurveyBatchWhereInput": {
            "surveyType": [
                659
            ],
            "__typename": [
                613
            ]
        },
        "SurveyBatchesList": {
            "items": [
                643
            ],
            "__typename": [
                613
            ]
        },
        "SurveyChoiceOption": {
            "Order": [
                332
            ],
            "Value": [
                613
            ],
            "text": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SurveyCreateInput": {
            "assignmentExpirationDays": [
                332
            ],
            "description": [
                613
            ],
            "hoodId": [
                324
            ],
            "isEnabled": [
                142
            ],
            "isPublic": [
                142
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "priority": [
                332
            ],
            "questions": [
                650
            ],
            "snoozeMaxAmount": [
                332
            ],
            "snoozeTTLDays": [
                332
            ],
            "title": [
                613
            ],
            "type": [
                659
            ],
            "__typename": [
                613
            ]
        },
        "SurveyQuestion": {
            "allowComments": [
                142
            ],
            "choiceOptions": [
                646
            ],
            "id": [
                324
            ],
            "order": [
                332
            ],
            "scaleOptions": [
                658
            ],
            "survey": [
                638
            ],
            "text": [
                613
            ],
            "type": [
                654
            ],
            "__typename": [
                613
            ]
        },
        "SurveyQuestionChoiceOptionCreateInput": {
            "order": [
                332
            ],
            "text": [
                613
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SurveyQuestionCreateInput": {
            "allowComments": [
                142
            ],
            "choiceOptions": [
                649
            ],
            "order": [
                332
            ],
            "scaleOptions": [
                653
            ],
            "text": [
                613
            ],
            "type": [
                654
            ],
            "__typename": [
                613
            ]
        },
        "SurveyQuestionResponseInput": {
            "comment": [
                613
            ],
            "questionId": [
                324
            ],
            "value": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SurveyQuestionResponseWhereInput": {
            "batchId": [
                324
            ],
            "questionId": [
                324
            ],
            "rating": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SurveyQuestionScaleOptionCreateInput": {
            "max": [
                332
            ],
            "maxText": [
                613
            ],
            "min": [
                332
            ],
            "minText": [
                613
            ],
            "steps": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "SurveyQuestionType": {},
        "SurveyQuestionUpdateInput": {
            "allowComments": [
                142
            ],
            "choiceOptions": [
                649
            ],
            "id": [
                324
            ],
            "order": [
                332
            ],
            "scaleOptions": [
                653
            ],
            "text": [
                613
            ],
            "type": [
                654
            ],
            "__typename": [
                613
            ]
        },
        "SurveyResponseSubmitInput": {
            "responses": [
                651
            ],
            "surveyAssignmentId": [
                324
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SurveyResponseUserSubmitInput": {
            "isCompleted": [
                142
            ],
            "responses": [
                651
            ],
            "surveyAssignmentId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SurveyScaleOption": {
            "max": [
                332
            ],
            "maxText": [
                613
            ],
            "min": [
                332
            ],
            "minText": [
                613
            ],
            "steps": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "SurveyType": {},
        "SurveyUpdateInput": {
            "description": [
                613
            ],
            "isEnabled": [
                142
            ],
            "isPublic": [
                142
            ],
            "name": [
                613
            ],
            "priority": [
                332
            ],
            "questions": [
                655
            ],
            "snoozeMaxAmount": [
                332
            ],
            "snoozeTTLDays": [
                332
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SurveyWhereInput": {
            "communityIds": [
                324
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SurveysList": {
            "items": [
                638
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "SyncJob": {
            "adapterId": [
                324
            ],
            "exportStatus": [
                667
            ],
            "forceUpdate": [
                142
            ],
            "id": [
                324
            ],
            "items": [
                669,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        671
                    ]
                }
            ],
            "organizationId": [
                324
            ],
            "results": [
                673
            ],
            "startedAt": [
                712
            ],
            "status": [
                674
            ],
            "type": [
                613
            ],
            "updatedAt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "SyncJobCreateInput": {
            "adapterId": [
                324
            ],
            "forceUpdate": [
                142
            ],
            "organizationId": [
                324
            ],
            "type": [
                675
            ],
            "__typename": [
                613
            ]
        },
        "SyncJobDownloadExportInput": {
            "organizationId": [
                324
            ],
            "syncJobId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SyncJobDownloadExportResult": {
            "url": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "SyncJobExportStatus": {},
        "SyncJobItem": {
            "entityData": [
                58
            ],
            "entityId": [
                324
            ],
            "entityType": [
                613
            ],
            "errors": [
                678
            ],
            "id": [
                324
            ],
            "jobId": [
                324
            ],
            "status": [
                670
            ],
            "vennId": [
                324
            ],
            "warnings": [
                678
            ],
            "__typename": [
                613
            ]
        },
        "SyncJobItemList": {
            "items": [
                668
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "SyncJobItemStatus": {},
        "SyncJobItemsWhereInput": {
            "entityIdSearchKey": [
                613
            ],
            "entityTypeIn": [
                352
            ],
            "statusIn": [
                353
            ],
            "__typename": [
                613
            ]
        },
        "SyncJobPrepareExportInput": {
            "organizationId": [
                324
            ],
            "syncJobId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "SyncJobResult": {
            "countPerStatus": [
                354
            ],
            "entityType": [
                352
            ],
            "__typename": [
                613
            ]
        },
        "SyncJobStatus": {},
        "SyncJobType": {},
        "SyncJobWhereInput": {
            "adapterId": [
                324
            ],
            "idIn": [
                324
            ],
            "startedBetween": [
                219
            ],
            "statusIn": [
                674
            ],
            "updatedBetween": [
                219
            ],
            "__typename": [
                613
            ]
        },
        "SyncJobsList": {
            "PageInfo": [
                435
            ],
            "items": [
                663
            ],
            "__typename": [
                613
            ]
        },
        "SyncMessage": {
            "code": [
                613
            ],
            "message": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Task": {
            "attachmentsBehaviour": [
                101
            ],
            "completedCtaText": [
                613
            ],
            "createdAt": [
                712
            ],
            "displayText": [
                613
            ],
            "embeddedApp": [
                258
            ],
            "embeddedAppId": [
                613
            ],
            "iconName": [
                613
            ],
            "id": [
                613
            ],
            "name": [
                613
            ],
            "organizationId": [
                613
            ],
            "requiresApproval": [
                142
            ],
            "template": [
                694
            ],
            "templateCss": [
                613
            ],
            "templateHtml": [
                613
            ],
            "type": [
                695
            ],
            "uncompletedCtaText": [
                613
            ],
            "updatedAt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "TaskCreateInput": {
            "attachmentsBehaviour": [
                101
            ],
            "completedCtaText": [
                613
            ],
            "displayText": [
                613
            ],
            "embeddedAppId": [
                613
            ],
            "iconName": [
                613
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "requiresApproval": [
                142
            ],
            "template": [
                694
            ],
            "templateCss": [
                613
            ],
            "templateHtml": [
                613
            ],
            "type": [
                695
            ],
            "uncompletedCtaText": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "TaskList": {
            "buildings": [
                172
            ],
            "fullTermsURL": [
                613
            ],
            "hoursUntilDue": [
                332
            ],
            "id": [
                324
            ],
            "isConnectedToUsers": [
                142
            ],
            "isDefault": [
                142
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "requireApproval": [
                142
            ],
            "shortTerms": [
                613
            ],
            "task": [
                684,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "tasks": [
                684
            ],
            "type": [
                689
            ],
            "__typename": [
                613
            ]
        },
        "TaskListCreateFromInput": {
            "name": [
                613
            ],
            "type": [
                689
            ],
            "__typename": [
                613
            ]
        },
        "TaskListCreateInput": {
            "fullTermsURL": [
                613
            ],
            "hoursUntilDue": [
                332
            ],
            "isDefault": [
                142
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "requireApproval": [
                142
            ],
            "shortTerms": [
                613
            ],
            "type": [
                689
            ],
            "__typename": [
                613
            ]
        },
        "TaskListTask": {
            "id": [
                324
            ],
            "isOptional": [
                142
            ],
            "order": [
                332
            ],
            "organizationId": [
                324
            ],
            "tMinusDays": [
                332
            ],
            "task": [
                679
            ],
            "taskId": [
                324
            ],
            "taskList": [
                681
            ],
            "taskListId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TaskListTaskCreateInput": {
            "isOptional": [
                142
            ],
            "order": [
                332
            ],
            "organizationId": [
                324
            ],
            "tMinusInDays": [
                332
            ],
            "taskId": [
                324
            ],
            "taskListId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TaskListTaskReorderInput": {
            "order": [
                332
            ],
            "taskListTaskId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TaskListTaskUpdateInput": {
            "isOptional": [
                142
            ],
            "tMinusInDays": [
                332
            ],
            "taskId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TaskListTaskWhereInput": {
            "taskIdsIn": [
                324
            ],
            "taskListIdsIn": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TaskListType": {},
        "TaskListUpdateInput": {
            "fullTermsURL": [
                613
            ],
            "hoursUntilDue": [
                332
            ],
            "name": [
                613
            ],
            "requireApproval": [
                142
            ],
            "shortTerms": [
                613
            ],
            "type": [
                689
            ],
            "__typename": [
                613
            ]
        },
        "TaskListWhereInput": {
            "Ids": [
                324
            ],
            "buildingIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TaskListsOverviewResponse": {
            "inspectionTaskList": [
                732
            ],
            "leaseContract": [
                359
            ],
            "moveInTaskList": [
                732
            ],
            "__typename": [
                613
            ]
        },
        "TaskListsOverviewWhereInput": {
            "buildingIds": [
                324
            ],
            "communityIds": [
                324
            ],
            "leaseContractIdIn": [
                324
            ],
            "leaseStartDateBetween": [
                219
            ],
            "__typename": [
                613
            ]
        },
        "TaskTemplate": {},
        "TaskType": {},
        "TaskUpdateInput": {
            "attachmentsBehavior": [
                101
            ],
            "completedCtaText": [
                613
            ],
            "displayText": [
                613
            ],
            "embeddedAppId": [
                613
            ],
            "iconName": [
                613
            ],
            "name": [
                613
            ],
            "requireApproval": [
                142
            ],
            "template": [
                694
            ],
            "templateCss": [
                613
            ],
            "templateHtml": [
                613
            ],
            "type": [
                695
            ],
            "uncompletedCtaText": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "TaskWhereInput": {
            "Ids": [
                324
            ],
            "searchKey": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "TaxRate": {
            "externalTaxRateId": [
                613
            ],
            "id": [
                324
            ],
            "value": [
                305
            ],
            "__typename": [
                613
            ]
        },
        "Team": {
            "buildingsCount": [
                332
            ],
            "communities": [
                192
            ],
            "communitiesCount": [
                332
            ],
            "id": [
                324
            ],
            "members": [
                428
            ],
            "membersCount": [
                332
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TeamBuildingPermissions": {
            "building": [
                172
            ],
            "buildingId": [
                324
            ],
            "permissions": [
                453
            ],
            "teamId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TeamBuildingPermissionsSetInput": {
            "buildingId": [
                324
            ],
            "permissionsSet": [
                453
            ],
            "teamId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TeamBuildingsPermissionsAddInput": {
            "buildingIds": [
                324
            ],
            "permissions": [
                453
            ],
            "teamId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TeamCreateInput": {
            "communityIds": [
                324
            ],
            "memberIds": [
                324
            ],
            "name": [
                613
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TeamDeleteInput": {
            "id": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "TeamUpdateInput": {
            "communityIds": [
                324
            ],
            "memberIds": [
                324
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "TeamsWhereInput": {
            "searchKey": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "TermsAndConditionsStatus": {},
        "Thermostat": {
            "availableDeviceModes": [
                233
            ],
            "availableFanModes": [
                291
            ],
            "buildingId": [
                613
            ],
            "buildingMode": [
                175
            ],
            "currentTemperature": [
                305
            ],
            "deviceMode": [
                233
            ],
            "fanMode": [
                291
            ],
            "id": [
                324
            ],
            "targetTemperature": [
                305
            ],
            "unit": [
                715
            ],
            "unitId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ThermostatTemperatureUpdateInput": {
            "newTemperature": [
                305
            ],
            "thermostatId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "ThermostatUpdateInput": {
            "DeviceMode": [
                233
            ],
            "FanMode": [
                291
            ],
            "newTemperature": [
                305
            ],
            "thermostatId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "TicketAttachmentType": {},
        "Time": {},
        "Timeline": {},
        "TriggerTaskListResponse": {
            "id": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "Unit": {
            "building": [
                172
            ],
            "buildingId": [
                324
            ],
            "externalId": [
                324
            ],
            "id": [
                324
            ],
            "isAcceptingApplications": [
                142
            ],
            "name": [
                613
            ],
            "status": [
                717
            ],
            "unitName": [
                613
            ],
            "updatedAt": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "UnitPublicData": {
            "buildingId": [
                324
            ],
            "buildingName": [
                613
            ],
            "id": [
                324
            ],
            "name": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "UnitStatus": {},
        "UnitType": {},
        "UnregisterUserDeviceInput": {
            "deviceId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "Upload": {},
        "User": {
            "appOnboardingStatus": [
                70
            ],
            "building": [
                172
            ],
            "buildingId": [
                324
            ],
            "email": [
                613
            ],
            "events": [
                287,
                {
                    "orderBy": [
                        288,
                        "[EventsOrderBy!]"
                    ],
                    "pager": [
                        436,
                        "PageInput!"
                    ],
                    "where": [
                        286
                    ]
                }
            ],
            "firstName": [
                613
            ],
            "hoodsAssigned": [
                317
            ],
            "hubMetadata": [
                322
            ],
            "id": [
                324
            ],
            "isSystemAdmin": [
                142
            ],
            "lastName": [
                613
            ],
            "leaseContracts": [
                359,
                {
                    "where": [
                        363
                    ]
                }
            ],
            "managedCommunities": [
                192,
                {
                    "where": [
                        382
                    ]
                }
            ],
            "metadata": [
                58
            ],
            "middleName": [
                613
            ],
            "mobileLastVisit": [
                712
            ],
            "mobilePhone": [
                613
            ],
            "organizationMemberships": [
                428
            ],
            "permittedBuildings": [
                454,
                {
                    "where": [
                        455,
                        "PermittedBuildingsWhereInput!"
                    ]
                }
            ],
            "profilePictureUrl": [
                613
            ],
            "resident": [
                514
            ],
            "socialProfile": [
                589
            ],
            "unit": [
                715
            ],
            "unitId": [
                324
            ],
            "userType": [
                743
            ],
            "__typename": [
                613
            ]
        },
        "UserAggregations": {
            "unopenedNotifications": [
                332
            ],
            "__typename": [
                613
            ]
        },
        "UserInviteInput": {
            "email": [
                613
            ],
            "firstName": [
                613
            ],
            "lastName": [
                613
            ],
            "mobilePhone": [
                613
            ],
            "role": [
                726
            ],
            "__typename": [
                613
            ]
        },
        "UserLoginApiKeyInput": {
            "apiKey": [
                613
            ],
            "userId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "UserLoginApiKeyResponse": {
            "token": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "UserRole": {},
        "UserRoot": {
            "amenities": [
                9,
                {
                    "orderBy": [
                        10,
                        "[AmenitiesOrderBy]"
                    ],
                    "pager": [
                        436
                    ]
                }
            ],
            "amenity": [
                12,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "appSettings": [
                75
            ],
            "awayNotice": [
                129,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "awayNotices": [
                129,
                {
                    "leaseContractId": [
                        324,
                        "ID!"
                    ],
                    "orderBy": [
                        131
                    ],
                    "where": [
                        138
                    ]
                }
            ],
            "deliveryPackages": [
                229,
                {
                    "orderBy": [
                        225
                    ],
                    "statuses": [
                        226,
                        "[DeliveryPackageStatus!]"
                    ]
                }
            ],
            "event": [
                268,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "events": [
                268,
                {
                    "where": [
                        286
                    ]
                }
            ],
            "featureFlags": [
                292,
                {
                    "where": [
                        293
                    ]
                }
            ],
            "hood": [
                317
            ],
            "hoodsAssigned": [
                317
            ],
            "leaseContracts": [
                359,
                {
                    "where": [
                        395
                    ]
                }
            ],
            "myAttendedEvents": [
                270,
                {
                    "communityId": [
                        324
                    ]
                }
            ],
            "myEventRating": [
                332,
                {
                    "eventId": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "notification": [
                405,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "notificationSnoozes": [
                410,
                {
                    "where": [
                        413
                    ]
                }
            ],
            "notifications": [
                419,
                {
                    "orderBy": [
                        420,
                        "[NotificationsOrderBy!]"
                    ],
                    "pager": [
                        436
                    ],
                    "where": [
                        418
                    ]
                }
            ],
            "orderedActiveUserTaskLists": [
                424,
                {
                    "where": [
                        425,
                        "OrderedActiveUserTaskListsWhereInput!"
                    ]
                }
            ],
            "reservation": [
                32,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "reservations": [
                32,
                {
                    "orderBy": [
                        44,
                        "[AmenityReservationOrderBy]"
                    ],
                    "where": [
                        56
                    ]
                }
            ],
            "serviceRequest": [
                629,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "serviceRequestAttachmentUploadUrl": [
                565
            ],
            "smartHome": [
                728
            ],
            "supportCategories": [
                624,
                {
                    "where": [
                        625,
                        "SupportCategoryWhereInput!"
                    ]
                }
            ],
            "supportTickets": [
                629,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        636
                    ]
                }
            ],
            "surveyAssignments": [
                639,
                {
                    "pager": [
                        436
                    ],
                    "where": [
                        642
                    ]
                }
            ],
            "surveyAssignmentsToDo": [
                639
            ],
            "thermostats": [
                708
            ],
            "user": [
                721
            ],
            "userId": [
                324
            ],
            "userTask": [
                729,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "visitor": [
                749,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "visitors": [
                749,
                {
                    "orderBy": [
                        750,
                        "VisitorOrderBy!"
                    ],
                    "where": [
                        753
                    ]
                }
            ],
            "__typename": [
                613
            ]
        },
        "UserSmartHomeRoot": {
            "lockCredentials": [
                373
            ],
            "lockProviderKeys": [
                376,
                {
                    "data": [
                        377
                    ]
                }
            ],
            "__typename": [
                613
            ]
        },
        "UserTask": {
            "dueDate": [
                712
            ],
            "id": [
                324
            ],
            "pmNotes": [
                613
            ],
            "responses": [
                737
            ],
            "status": [
                739
            ],
            "taskListTask": [
                684
            ],
            "taskListTaskId": [
                324
            ],
            "user": [
                721
            ],
            "userId": [
                324
            ],
            "userTaskList": [
                732
            ],
            "userTaskListId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "UserTaskAttachment": {
            "URL": [
                613
            ],
            "contentType": [
                613
            ],
            "key": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "UserTaskAttachmentInput": {
            "contentType": [
                613
            ],
            "key": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "UserTaskList": {
            "approvedAt": [
                712
            ],
            "approvedBy": [
                721
            ],
            "approvedById": [
                613
            ],
            "dueDate": [
                712
            ],
            "id": [
                324
            ],
            "isActive": [
                142
            ],
            "leaseContract": [
                359
            ],
            "leaseContractId": [
                324
            ],
            "organizationId": [
                324
            ],
            "status": [
                733
            ],
            "submittedAt": [
                712
            ],
            "task": [
                729,
                {
                    "id": [
                        324,
                        "ID!"
                    ]
                }
            ],
            "taskList": [
                681
            ],
            "taskListId": [
                324
            ],
            "tasks": [
                729
            ],
            "__typename": [
                613
            ]
        },
        "UserTaskListStatus": {},
        "UserTaskListSubmitResponse": {
            "id": [
                324
            ],
            "userTaskListStatus": [
                733
            ],
            "__typename": [
                613
            ]
        },
        "UserTaskListUpdateInput": {
            "status": [
                733
            ],
            "__typename": [
                613
            ]
        },
        "UserTaskListUpdateResponse": {
            "id": [
                324
            ],
            "status": [
                733
            ],
            "__typename": [
                613
            ]
        },
        "UserTaskResponse": {
            "attachments": [
                730
            ],
            "text": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "UserTaskResponseInput": {
            "attachments": [
                731
            ],
            "text": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "UserTaskStatus": {},
        "UserTaskStatusUpdateResponse": {
            "userTaskListStatus": [
                733
            ],
            "userTaskStatus": [
                739
            ],
            "__typename": [
                613
            ]
        },
        "UserTaskUpdateInput": {
            "Responses": [
                738
            ],
            "pmNotes": [
                613
            ],
            "status": [
                739
            ],
            "__typename": [
                613
            ]
        },
        "UserTasksWhereInput": {
            "userTaskListId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "UserType": {},
        "UserWhereInput": {
            "unitIn": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "UsersList": {
            "items": [
                721
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "ValidationConstraint": {},
        "ValidatorConstraint": {
            "name": [
                746
            ],
            "param": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "VisitTime": {},
        "Visitor": {
            "authorizedDeskKey": [
                142
            ],
            "email": [
                613
            ],
            "endDate": [
                712
            ],
            "endTime": [
                613
            ],
            "firstName": [
                613
            ],
            "hasKey": [
                142
            ],
            "id": [
                324
            ],
            "isCanceled": [
                142
            ],
            "isCheckedIn": [
                142
            ],
            "keyNumber": [
                613
            ],
            "lastName": [
                613
            ],
            "leaseContract": [
                359
            ],
            "leaseContractId": [
                324
            ],
            "lockoutAssistance": [
                142
            ],
            "notes": [
                613
            ],
            "organizationId": [
                324
            ],
            "phoneNumber": [
                613
            ],
            "recurrenceEndType": [
                496
            ],
            "recurrenceNumOccurrences": [
                332
            ],
            "recurrenceWeekdays": [
                220
            ],
            "startDate": [
                712
            ],
            "startTime": [
                613
            ],
            "status": [
                751
            ],
            "time": [
                748
            ],
            "__typename": [
                613
            ]
        },
        "VisitorOrderBy": {},
        "VisitorStatus": {},
        "VisitorUpsertInput": {
            "authorizedDeskKey": [
                142
            ],
            "email": [
                613
            ],
            "endDate": [
                712
            ],
            "endTime": [
                613
            ],
            "firstName": [
                613
            ],
            "hasKey": [
                142
            ],
            "lastName": [
                613
            ],
            "leaseContractId": [
                324
            ],
            "lockoutAssistance": [
                142
            ],
            "notes": [
                613
            ],
            "phoneNumber": [
                613
            ],
            "recurrenceEndType": [
                496
            ],
            "recurrenceNumOccurrences": [
                332
            ],
            "recurrenceWeekdays": [
                220
            ],
            "startDate": [
                712
            ],
            "startTime": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "VisitorWhereInput": {
            "endDateLt": [
                712
            ],
            "leaseContractIdsIn": [
                324
            ],
            "searchName": [
                613
            ],
            "startDateGt": [
                712
            ],
            "statuses": [
                751
            ],
            "timeIn": [
                748
            ],
            "__typename": [
                613
            ]
        },
        "VisitorsSettings": {
            "buildingId": [
                324
            ],
            "id": [
                324
            ],
            "isAuthorizedDeskKeyEnabled": [
                142
            ],
            "isLockoutAssistanceEnabled": [
                142
            ],
            "isPhysicalKeyEnabled": [
                142
            ],
            "isSmartAccessEnabled": [
                142
            ],
            "isVisitorsEnabled": [
                142
            ],
            "__typename": [
                613
            ]
        },
        "VisitorsSettingsUpsertInput": {
            "buildingId": [
                324
            ],
            "isAuthorizedDeskKeyEnabled": [
                142
            ],
            "isLockoutAssistanceEnabled": [
                142
            ],
            "isPhysicalKeyEnabled": [
                142
            ],
            "isSmartAccessEnabled": [
                142
            ],
            "isVisitorsEnabled": [
                142
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "WebLinkData": {
            "openInBrowser": [
                142
            ],
            "url": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "WebLinkDataInput": {
            "openInBrowser": [
                142
            ],
            "url": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "WhereAppSettingsInput": {
            "leaseContractId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "WhiteLabel": {
            "appIcon": [
                613
            ],
            "appLink": [
                65
            ],
            "appName": [
                613
            ],
            "brandId": [
                324
            ],
            "id": [
                324
            ],
            "organizationId": [
                324
            ],
            "privacyPolicyURL": [
                613
            ],
            "requireExplicitTermsAcceptance": [
                142
            ],
            "termsAndConditionsURL": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "WhiteLabelConfig": {
            "colors": [
                144
            ],
            "emailOTPLogo": [
                613
            ],
            "privacyPolicyURL": [
                613
            ],
            "requireExplicitTermsAcceptance": [
                142
            ],
            "termsAndConditionsURL": [
                613
            ],
            "whiteLabelId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "WhiteLabelCreateInput": {
            "appIcon": [
                613
            ],
            "appLink": [
                66
            ],
            "appName": [
                613
            ],
            "brandId": [
                324
            ],
            "organizationId": [
                324
            ],
            "privacyPolicyURL": [
                613
            ],
            "requireExplicitTermsAcceptance": [
                142
            ],
            "termsAndConditionsURL": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "WhiteLabelUpdateInput": {
            "appIcon": [
                613
            ],
            "appLink": [
                66
            ],
            "appName": [
                613
            ],
            "privacyPolicyURL": [
                613
            ],
            "requireExplicitTermsAcceptance": [
                142
            ],
            "termsAndConditionsURL": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "amenityCalendarUnavailabilitySetInput": {
            "AmenityIds": [
                324
            ],
            "endDate": [
                712
            ],
            "reason": [
                613
            ],
            "startDate": [
                712
            ],
            "__typename": [
                613
            ]
        },
        "authChallengeType": {},
        "externalLockProviderGroup": {
            "entityId": [
                324
            ],
            "entityType": [
                267
            ],
            "id": [
                324
            ],
            "lockProvider": [
                378
            ],
            "lockProviderGroupId": [
                613
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "externalLockProviderGroupCreateInput": {
            "entityId": [
                324
            ],
            "entityType": [
                267
            ],
            "lockProvider": [
                378
            ],
            "lockProviderGroupId": [
                613
            ],
            "organizationId": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "externalLockProviderGroupList": {
            "items": [
                765
            ],
            "pageInfo": [
                435
            ],
            "__typename": [
                613
            ]
        },
        "externalLockProviderGroupUpdateInput": {
            "entityId": [
                324
            ],
            "entityType": [
                267
            ],
            "lockProvider": [
                378
            ],
            "lockProviderGroupId": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "interestGroupsAudienceParamsInput": {
            "interestGroupIds": [
                324
            ],
            "__typename": [
                613
            ]
        },
        "supportTicketNotificationText": {
            "message": [
                613
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        },
        "supportTicketNotificationTextInput": {
            "message": [
                613
            ],
            "title": [
                613
            ],
            "__typename": [
                613
            ]
        }
    }
}